import React from 'react';
import '../App.css';
import { Button } from 'react-bootstrap';


const LogoutButton = () => {
  const handleLogoutClick = () => {
      // Delete the JWT token cookie
      console.log("Logging out...");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.reload();
  };

  return (
    <Button variant='secondary' className='roomy-button' onClick={handleLogoutClick}>Logout</Button>
  );
};

export default LogoutButton;
