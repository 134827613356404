import React, { useEffect,useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { loggit, handleApiError } from '../utils/helpers';
import { getCookie } from '../utils/cookie';

import WhatsappPendingMeals from '../components/WhatsappPendingMeals';

const WhatsappMeals = ({ isOpen, onClose, meals }) => {
    const jwtToken = getCookie('jwtToken');

    const [loading, setLoading] = useState(true);

    const [pendingMeals, setPendingMeals] = useState(null);
    const updatePendingMeals = (mealPrepId) => {
        // if there are pending meals, remove the one that was just logged
        loggit('Updating pending meals list, removing mealPrepId: ', mealPrepId)
        if (pendingMeals.length > 0) {
            const updatedPendingMeals = pendingMeals.filter(meal => meal.meal_prep_id !== mealPrepId);
            setPendingMeals(updatedPendingMeals);
        }
    };

    const getWhatsappPendingMeals = async () => {
        // Get the whatsapp pending meals from the API endpoint get/whatsapp/pending
        try {
            const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}get/whatsapp/pending`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                }
            });
            await handleApiError(response);
            const data = await response.json();
            loggit('Whatsapp pending meals data retrieved: ', data);
            return data;
        } catch(error) {
            loggit('     • Error getting whatsapp pending meals data:', error);
        }
    };
    useEffect(() => {
        if (isOpen) {
            getWhatsappPendingMeals()
            .then(data => {
                setPendingMeals(data);
                setLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);



    return (
        <>
        {loading ? (
            // Show a loading spinner or some other placeholder
            <div className='d-flex justify-content-center'></div>
        ) : (
            <Modal  
                show={isOpen} 
                onHide={onClose}
                size="xl"
                fullscreen="md-down"
                className="mb-5 top-aligned-modal blurred-modal">
            <Modal.Header closeButton>
                <Modal.Title>WhatsApp Meal Placeholders</Modal.Title>
            </Modal.Header>

            <Modal.Body className='no-padding'>
                <div className='meal-cards-container'>
                {pendingMeals.length > 0 
                    ? pendingMeals.map(meal => <WhatsappPendingMeals key={meal.meal_prep_id} meal={meal} removeMeal={updatePendingMeals} />)
                    : <div className='p-5'>
                        <h3 className='fudrik-text-light'>You're all caught up!<br/>Go on... get outta here!</h3>
                        <h5><i>No outstanding meal placeholders.</i></h5>
                            Did you know you could enter in your meals by sending Füdrik a WhatsApp message? You can also take a photo of your meal to create a placeholder.
                            This means that you can log the meal later on whenever you have a free moment, but Füdrik remembers when you took the photo. So besides having a photo reminder of what you ate to assist in the logging,
                            you've got the time logged perfectly as well. Easy squeezy.
                            <br/><br/> 
                            At the moment, this feature is available to a limited number of members only.
                            But in the coming months we'll be rolling this out to everybody. 
                            <br/> <i>Thy meal be done!</i>
                    </div>
                }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" className='mb-5' onClick={onClose}>
                Close
                </Button>
                <hr/>
            </Modal.Footer>
            </Modal>
        )}
        </>
    );
};

export default WhatsappMeals;
