import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loggit, handleApiError } from '../utils/helpers';
import { getCookie } from '../utils/cookie';
import { Modal, Button } from 'react-bootstrap';

import { useSettings } from '../settings/SettingsContext';



const physicalStatsValidationSchema = Yup.object().shape({
    original_height: Yup.number()
        .nullable()
        .min(0, 'Original height must be a positive number'),
    current_height: Yup.number()
        .nullable()
        .min(0, 'Current height must be a positive number'),
    age: Yup.number()
        .nullable()
        .integer()
        .min(0, 'Age must be a positive number'),
    gender: Yup.string()
        .oneOf(['Male', 'Female'], 'Gender must be either Male or Female')
        .required('Gender is required'),
    weight: Yup.number()
        .nullable()
        .min(0, 'Weight must be a positive number'),
});


//============================================================================================
//=================================== BEGIN SETTINGS MODAL ===================================
//============================================================================================
function ProfileModal( { show, onClose } ) {
    const { isSettingsLoading } = useSettings();

    const jwtToken = getCookie('jwtToken');
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
          original_height: '',
          current_height: '',
          age: '',
          gender: '',
          activity_level: '',
          weight: '',
          bmi: '',
          bmr: '',
          tdee: '',
        });
  
    const activityLevelSelected = useRef({
          title: '',
          value: '', 
        });
    
    //@@@@@@@@@@ FETCH PHYSICAL STATS DATA FROM API @@@@@@@@@@
    useEffect (() => {
      loggit('fetching data from api...')
      const fetchPhysicalStats = async () => {
        // loggit(`API URI: ${process.env.REACT_APP_API_PROFILE_URL}fluid/physical-stats-list/get`);
        try {
          const response_physical_stats = await fetch(`${process.env.REACT_APP_API_PROFILE_URL}fluid/physical-stats-list/get`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          });
          await handleApiError(response_physical_stats);
          const data = await response_physical_stats.json();
          loggit('response_physical_stats data: ', data);
          setInitialValues({
            original_height: data.physical_stats_list.original_height,
            current_height: data.physical_stats_list.current_height,
            age: data.physical_stats_list.age,
            gender: data.physical_stats_list.gender,
            activity_level: data.physical_stats_list.activity_level.title,
            weight: data.physical_stats_list.weight,
            bmi: data.physical_stats_list.bmi,
            bmr: data.physical_stats_list.bmr,
            tdee: data.physical_stats_list.tdee,
          });
          activityLevelSelected.current = {
            title: data.physical_stats_list.activity_level.title,
            value: data.physical_stats_list.activity_level.value,
          };
          
          setLoading(false);
        } catch (error) {
          loggit('     - initiating physical stats error: ', error);
          alert(error.message);
        }
        // loggit ('     - loading is now: ', loading);
      };
      
      fetchPhysicalStats();
      //eslint-disable-next-line
    }, []);
  

    //@@@@@@@@@@ ACTIVITY LEVEL DROPDOWN - Handle Selection @@@@@@@@@@
    const handleActivityLevelChange = (selectedOption) => {
      const activityLevelValues = {
        'Sedentary': 1.2,
        'Slightly Active': 1.375,
        'Moderately Active': 1.55,
        'Very Active': 1.725,
        'Super Active': 1.9,
      };
      
      const activityLevelValue = activityLevelValues[selectedOption.trim()];
      
      activityLevelSelected.current = {
        title: selectedOption,
        value: activityLevelValue,
      };
      setInitialValues({
        ...initialValues,
        activity_level: selectedOption,
      });
      loggit('     activityLevelSelected is now: ', activityLevelSelected.current);
    };


    //@@@@@@@@@@ PHYSICAL STATS FORM SUBMISSION @@@@@@@@@@
    const handlePhysicalStatsSubmit = async (values) => {
      loggit('handlePhysicalStatsSubmit values: ', values);
      // prepare physical stats data for submission
      const physicalStatsData = {
        physical_stats_list: {
          original_height: values.original_height,
          current_height: values.current_height,
          age: values.age,
          gender: values.gender,
          activity_level: activityLevelSelected.current,
          weight: values.weight,
          bmi: values.bmi,
          bmr: values.bmr,
          tdee: values.tdee,
        }
      };
      loggit(`API URI: ${process.env.REACT_APP_API_PROFILE_URL}fluid/physical-stats-list/update using object ${JSON.stringify(physicalStatsData)}`);
      try {
        const response_physical_stats_update = await fetch(`${process.env.REACT_APP_API_PROFILE_URL}fluid/physical-stats-list/update`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(physicalStatsData),
        });
        await handleApiError(response_physical_stats_update);
        const data = await response_physical_stats_update.json();
        loggit('response_physical_stats_update data: ', data);

        setInitialValues({
          original_height: data.physical_stats_list.original_height,
          current_height: data.physical_stats_list.current_height,
          age: data.physical_stats_list.age,
          gender: data.physical_stats_list.gender,
          activity_level: data.physical_stats_list.activity_level.title,
          weight: data.physical_stats_list.weight,
          bmi: data.physical_stats_list.bmi,
          bmr: data.physical_stats_list.bmr,
          tdee: data.physical_stats_list.tdee,
        });
        activityLevelSelected.current = {
          title: data.physical_stats_list.activity_level.title,
          value: data.physical_stats_list.activity_level.value,
        };
       
      } catch (error) {
        loggit('     - physical stats update error: ', error);
        alert(error.message);
      }
      onClose();

    };


    if (!show || isSettingsLoading) return null;

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% MAKE IT PURTY %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    return (
      <Modal 
        show={show} 
        onHide={onClose} 
        onClose={onClose}
        className='blurred-modal'
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Physical Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {loading ? (
              <p>Loading...</p>
              ) : (
                <Formik
                initialValues={initialValues}
                validationSchema={physicalStatsValidationSchema}
                onSubmit={handlePhysicalStatsSubmit}
                >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Form>
                <div>
                  <div className='d-flex flex-column align-items-center'>
                    <div className='w-50'>
                      <div className="form-group">
                        <label htmlFor="current_height">Height (cm)</label>
                        <Field name="current_height" type="number" className="form-control" />
                        <ErrorMessage name="current_height" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="weight">Weight (kg)</label>
                        <Field name="weight" type="number" className="form-control" />
                        <ErrorMessage name="weight" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="age">Age</label>
                        <Field name="age" type="number" className="form-control" />
                        <ErrorMessage name="age" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="gender">Gender</label>
                        <Field 
                          as="select" 
                          name="gender" 
                          className="form-control" 
                          value={values.gender}
                        >
                          <option value="">Please select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Field>
                        <ErrorMessage name="gender" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="activity_level">Activity Level</label>
                        <Field
                          as="select"
                          name="activity_level"
                          className="form-control"
                          value={values.activity_level}
                          onChange={(e) => {
                            handleActivityLevelChange(e.target.value);
                            handleChange(e);
                          }}
                        >
                          <option value="Sedentary">Sedentary</option>
                          <option value="Slightly Active">Slightly Active</option>
                          <option value="Moderately Active">Moderately Active</option>
                          <option value="Very Active">Very Active</option>
                          <option value="Super Active">Super Active</option>
                        </Field>
                        <ErrorMessage name="activity_level" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="bmi">BMI</label>
                        <Field name="bmi" type="number" className="form-control gray-input" readOnly />
                        <ErrorMessage name="bmi" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="bmr">BMR</label>
                        <Field name="bmr" type="number" className="form-control gray-input" readOnly />
                        <ErrorMessage name="bmr" component="div" className="text-danger" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="tdee">TDEE</label>
                        <Field name="tdee" type="number" className="form-control gray-input" readOnly />
                        <ErrorMessage name="tdee" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                    <hr/>
                  <div className='right-aligned-buttons'>
                    <Button variant="secondary" className={`modal-cancel-button w-50`} onClick={onClose} >Cancel</Button>
                    <Button type="submit" variant="warning" className='w-50'>Save Physical Stats</Button>
                  </div>
                </div>
              </Form>
              )}
            </Formik>
            )}
          </div>
          </Modal.Body>
          </Modal>
    );
}

export default ProfileModal;

