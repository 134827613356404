import { useEffect, useRef, useState } from 'react';
import { loggit, transcribeAudio } from '../utils/helpers';
import { Modal, Container, Button, Row, Col } from 'react-bootstrap';

import { useSettings } from '../settings/SettingsContext';

import LongPressButton from '../components/LongPressButton';
import SpeechSynthesis from '../components/SpeechSynthesis';


const SpeechRecognition = ({ 
        // parent props we're watching
        isEnabled, 
        isListening,
        processBarActivated,
        processBarMessage,
        focusTextInput,
        transcriptMessage,
        assistantMessage,
        spokenMessage,
        transcribedText,
        isFetching,
        isLastStep,
        conversationModeOverride,
        
        // callback functions
        handleDisabled,
        toggleIsListening,
        setSpokenMessage,
        focusTextInputDone,
        setTranscribedText,
        triggerApiCall,
        toggleApiCalling,
        setConversationModeOverride,
        }) => {

    const { settings, isMobile } = useSettings();   
    const recognition = useRef(null);
    
    const buttonSizeClass = settings.accessibility ? "btn-lg" : "btn-sm";
    const transcriptionFontSize = settings.accessibility ? "1.5rem" : "1rem";

    
    //_______________________________________________________________________________________
    //@@@@@@@@@@ handle the transcribed text input @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // transcribedText is passed from parent as a prop
    const newTranscribedText = useRef(''); // used to hold the new transcribed text with which we summarize meal
    const lastFinalTranscript = useRef("");
    useEffect(() => {
        newTranscribedText.current = transcribedText;
    }, [transcribedText]);
    //-------------------------------------------------------------------------------------
    

    //______________________________________________________________________________________
    //@@@@@@@@@@ HANDLE A NEW TRANSCRIBED TEXT COMING FROM SPEECHRECOGNITION.onresult @@@@@@
    const handleNewTranscribedText = (new_Text) => {
        console.log('handleNewTranscribedText called with: ', new_Text);
        
        new_Text = newTranscribedText.current ? `${newTranscribedText.current} ${new_Text}` : new_Text; // append to thisTranscribedText if thisTranscribedText is not empty
        setTranscribedText(new_Text);
        
        if (settings.fudrik_speaks && !conversationModeOverride) {
            startCountdown();
        }
    };
    //-------------------------------------------------------------------------------------
    

    //______________________________________________________________________________________
    //@@@@@@@@@@ HANDLE SUBMITTING THE TRANSCRIBED TEXT @@@@@@@@@
    const submitTranscription = () => {
        loggit('Attempting to submit transcription: ', newTranscribedText.current);
        //callback to parent to trigger the api call
        if (!isFetching) triggerApiCall(newTranscribedText.current);
    };
    //-------------------------------------------------------------------------------------


    //_______________________________________________________________________________________
    //@@@@@@@@@@ handle focusing into the transcribed text input @@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const transcribedTextInputRef = useRef(null);   
    const focusTranscribedTextInput = () => transcribedTextInputRef.current.focus();
    useEffect(() => {
        if (focusTextInput) {
            focusTranscribedTextInput();
            focusTextInputDone(); // resetting the focusTextInput "trigger" to false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusTextInput]);
    //-------------------- END FOCUSING ----------------------


    //________________________________________________________________________________________________________________
    //@@@@@@@@@@ MANAGE isListening to Start and Stop listening and update the modalMessage accordingly @@@@@@@@@@@@@@@    
    useEffect(() => { // start and stop listening when isListening changes (whether triggered internally or by parent component via startStopListening)
        loggit('     • isListening changed to: ', isListening)
        if (isMobile){
            isListening ? startRecording() : stopRecording();
        } else {
            if (recognition.current) {
                isListening ? recognition.current.start(): recognition.current.stop();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isListening]);
    //------------------------------------------------------------------------------






    //_______________________________________________________________________________________
    //=======================================================================================
    //@@@@@@@@@@ IF MOBILE, HANDLE SPEECH to TEXT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const isRecording = useRef(false);
    var localAudioChunks = [];

    const startRecording = async () => {
        if (isMobile) {
            loggit("startRecording");
            // reset localAudioChunks
            localAudioChunks = [];
            
            try {    
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setMediaStream(stream);
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                // Add event listeners
                recorder.addEventListener('dataavailable', handleRecordingDataAvailable);
                recorder.addEventListener('stop', handleRecordingStop);
                recorder.start();
                isRecording.current = true;
            } catch (error) {
                isRecording.current = false;
                console.error('Error accessing the microphone:', error);
            }
        } else {
            loggit("ERROR - this shouldn't be called when not on mobile");
        }
    };
    
    const stopRecording = () => {
        if (isMobile) {
            loggit("stopRecording")
            if (isRecording.current === true) {
                if (mediaRecorder) {
                    mediaRecorder.stop();
                }
            }
        } else {
            loggit("ERROR - this shouldn't be called when not on mobile");
        }
    };
    
    const handleRecordingDataAvailable = (event) => {
        if (isMobile) {
            localAudioChunks.push(event.data);
        } else {
            loggit("ERROR - this shouldn't be called when not on mobile");
        }
    };

    const handleRecordingStop = async () => {
        if (isMobile) {
            if (isEnabled) {
                loggit("FULL STOP - Attempting to transcribeAudio");
    
                const audioBlob = new Blob(localAudioChunks, { type: 'audio/webm: codecs=opus' });
                let transcription = await transcribeAudio(audioBlob);
                if (transcription) {
                    handleNewTranscribedText(transcription);
                } else {
                    loggit('     • Error transcribing the audio');
                    alert('Apologies... Something went wrong. Please try again later.');
                }
    
                shutdownSpeechRecognition();
            }
        } else {
            loggit("ERROR - this shouldn't be called when not on mobile");
        }
    };
    //==================================================================
    //----------------------- END SPEECH to TEXT -----------------------





    //_____________________________________________________________
    //=============================================================
    //@@@@@@@@@@ SPEECH RECOGNITION - SPINUP @@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (isEnabled && !isMobile) {
            loggit("SpeechRecognition is attempting to spin up - speechRec is set to: ", settings.speech_recognition);
            const spinupSpeechRecognition = () => {
                // ... speech recognition configurations
                const newRecognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
                newRecognition.interimResults = true;
                newRecognition.continuous = true;
                newRecognition.lang = 'en-US';
                newRecognition.maxAlternatives = 1;

                newRecognition.onspeechstart = () => {
                    loggit("..... You're speaking");
                    if (settings.fudrik_speaks) resetCountdown();
                };
                
                newRecognition.onspeechend = () => {
                    loggit("..... You're not speaking");
                };
               
                newRecognition.onend = () => {
                    toggleIsListening(false);
                };
                
                newRecognition.onerror = (event) => {
                    loggit('Recognition error:', event.error);
                    if (event.error === 'aborted') { // Speech Recognition in use in another tab!!! shut it down...
                        setSpokenMessage('Apologies... It appears another web page is already using speech recognition. Please disable it and try again. Or, you can manually enter your meal in the form provided.');
                        shutdownSpeechRecognition();
                    } else if (event.error === 'no-speech') {
                        loggit('Browser does not support speech recognition');
                        shutdownSpeechRecognition();
                        setConversationModeOverride(true);
                    }
                };

                newRecognition.onresult = (event) => {
                    // loggit("Speech Recognition >> onresult << called: ", event);
                    var new_transcript = "";
                    for (let i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            new_transcript = event.results[i][0].transcript;
                            if (isMobile) {
                                // loggit("    lastFinalTranscript: ", lastFinalTranscript); // for deep debugging
                                // loggit("        new_transcript: ", new_transcript);  // for deep debugging
                                if (new_transcript !== lastFinalTranscript.current) {
                                    let last_word = new_transcript.replace(lastFinalTranscript.current, "").trim() + " ";
                                    // loggit("            (extracted) last_word: ", last_word);  // for deep debugging
                                    if (last_word !== "") handleNewTranscribedText(last_word);
                                    lastFinalTranscript.current = new_transcript;
                                }
                            } else {
                                if (new_transcript !== "") handleNewTranscribedText(new_transcript);
                            };
                            loggit('     • Final transcript:', new_transcript); // Log each final transcript
                        } else {
                            // loggit('Non-final transcript:', event.results[i][0].transcript);  // for deep debugging
                        };
                    };
                    if (settings.fudrik_speaks) resetCountdown();
                };
                recognition.current = newRecognition; // make it available to the rest of the component    
            };

            spinupSpeechRecognition();

            return () => {
                if(recognition.current) recognition.current.stop();
            };
        } else if (!isEnabled && !isMobile) {
            loggit("SpeechRecognition is shutting down");
            shutdownSpeechRecognition();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled]);
    //---------------- END SPEECH RECOGNITION - SPINUP -----------------


    //_______________________________________________________________________________________
    //@@@@@@@@@@ SPEECH RECOGNITION - SHUTDOWN @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const shutdownSpeechRecognition = () => {
        if (isMobile) {
            isRecording.current = false;
            localAudioChunks = [];
        
            if (mediaRecorder) {
                mediaRecorder.removeEventListener('dataavailable', handleRecordingDataAvailable);
                mediaRecorder.removeEventListener('stop', handleRecordingStop);
                
                if (mediaStream) {
                    mediaStream.getTracks().forEach((track) => track.stop());
                    setMediaStream(null);
                }
                setMediaRecorder(null);
            }
        } else {
            // if countdown is running, clear it and reset the countdown
            stopCountdown();

            // if speech recognition is running, stop it and set to null
            loggit("Shutting down speech recognition, resetting onend, stopping and setting to null");

            toggleIsListening(false);
            if (recognition.current) {
                recognition.current.onend = () => { };
                recognition.current.stop();
                recognition.current = null;
            }
        }
    };
    //-------------- END SPEECH RECOGNITION -----------------


    //_____________________________________________________________
    //@@@@@@@@@@ AUTO SUBMIT COUNTDOWN TIMER @@@@@@@@@@@@@@@@@@@@@@
    let timerInterval = useRef(null);
    const autoSubmitSpeed = 250;
    const autoSubmitCountdownLength = 10;
    let autoSubmitCountdown = useRef(autoSubmitCountdownLength);

    function startCountdown() {
        if (timerInterval.current) return; // If countdown is already running, do nothing

        timerInterval.current = setInterval(() => {
            autoSubmitCountdown.current -= 1;
            if (autoSubmitCountdown.current <= 0) {
                console.log('>>>>> auto submitting response')
                resetCountdown();

                clearInterval(timerInterval.current);
                timerInterval.current = null; // Ensure timerInterval.current is set to null after clearing
                
                submitTranscription();
            } else {
                console.log(`AutoSubmit Countdown: ${autoSubmitCountdown.current}`); 
            }
        }, autoSubmitSpeed); 
    };

    function resetCountdown() {
        autoSubmitCountdown.current = autoSubmitCountdownLength;
    };

    function stopCountdown() {
        if (timerInterval.current) {
            clearInterval(timerInterval.current);
            timerInterval.current = null; // Ensure timerInterval.current is set to null after clearing
        }
        resetCountdown();
    };
    //-------------- END AutoSubmit Countdown Timer -----------------


    //_______________________________________________________________________________________
    //@@@@@@@@@@ SPEECH SYNTHESIS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleSpeechEnd = () => {
        loggit('Speech Synthesis is done...');
        toggleApiCalling(false);
    };
    //---------------- END SPEECH SYNTHESIS -----------------
    

    //_______________________________________________________________________________________
    //@@@@@@@@@@ IS-LISTENING ANIMATION (LOADING BAR) COMPONENT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    function ProcessingBar() {
        return (
            <div>
                <div className='d-flex justify-content-center'>
                    <div className='loading-bar' />
                </div>
                <div className='d-flex justify-content-center'>
                    <h6 className='modal-message text-danger px-4'>{processBarMessage}</h6>
                </div>
            </div>
        );
    };
    //-------------------------------------------------------------------------------------


    if (!isEnabled) return null;

    return (
        <Container>
            <div className="transcript-message"  style={{ display: 'none' }}>
                <SpeechSynthesis text={spokenMessage} onSpeechEnd={handleSpeechEnd} isFudrikSpeakEnabled={settings.fudrik_speaks} />
            </div>
            <Modal.Body>
            {processBarActivated && <ProcessingBar /> }
                <Row className="justify-content-center">
                    <Row className='no-padding'>
                        <div><h4 className="transcript-message">{transcriptMessage}</h4></div>
                        <textarea
                            type = "text"
                            className='transcribed-text'
                            id = 'transcribed-text-input'
                            ref = {transcribedTextInputRef}
                            placeholder = {assistantMessage}
                            value = {transcribedText}
                            onChange = {(e) => setTranscribedText(e.target.value)}
                            style = {{ 
                                width: '100%',
                                fontSize: `${transcriptionFontSize}`, 
                                resize: 'auto',
                                overflow: 'auto',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                padding: '10px',
                                minHeight: `calc(4 * ${transcriptionFontSize} * ${transcriptionFontSize} )`,
                            }}
                            />
                    </Row>
                    
                    {(settings.speech_recognition && (!settings.fudrik_speaks || conversationModeOverride)) && (
                        <>
                        <Row className='w-100 no-padding'>
                            <Button variant="danger" className={`w-100 no-padding ${buttonSizeClass}`} onClick={() => setTranscribedText('')}>Reset</Button>
                        </Row>
                        {settings.speech_recognition && (
                            <Row className='w-100 no-padding'>
                                <Col xs={6} className='text-center right-padding no-padding py-1'>
                                    <Button variant="success" className={`${buttonSizeClass} w-100 ${isListening ? 'disabled-button': ''}`} disabled={isListening} style={{padding: "8px"}}  onClick={() => toggleIsListening(true)}>Start Recording</Button>
                                </Col>
                                <Col xs={6} className='text-center left-padding no-padding py-1'>
                                    <Button variant="success" className={`${buttonSizeClass} w-100 ${!isListening ? 'disabled-button': ''}`} disabled={!isListening} style={{padding: "8px"}}  onClick={() => toggleIsListening(false)}>Stop Recording</Button>
                                </Col>
                            </Row>
                        )}
                        </>
                    )}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        {(!isLastStep || !settings.fudrik_speaks) && (
                            <Row className='justify-content-end'>
                                {!settings.accessibility && (
                                    <div className='modal-action-buttons ml-auto'>
                                        <Button 
                                            className={`me-2 button-rounded modal-action-button ${buttonSizeClass} outer-glow-dark`} 
                                            onClick={submitTranscription} 
                                            variant='secondary'
                                            style={{padding: "12px 30px"}}
                                            >Send Message</Button>
                                    </div>
                                )}
                                {settings.accessibility && (
                                    <div className='justify-content-center ml-auto'>
                                        <LongPressButton 
                                            initialColor="gray" 
                                            finalColor="yellow" 
                                            holdTime={1500} 
                                            text="Press & Hold to Send Message" 
                                            onSubmit={submitTranscription}

                                        />
                                    </div>
                                )}
                            </Row>
                        )}
            </Modal.Footer>
        </Container>
    );

};

export default SpeechRecognition;



//============================================== EMBED STUFFS ======================================================
// import { Modal, Container, Button, Row } from 'react-bootstrap';
// import SpeechRecognition from '../components/SpeechRecognition';
// import { useSettings } from '../settings/SettingsContext';

//     const {settings } = useSettings();
    // //_____________________________________________________________________________________
    // //@@@@@@@@@@ SPEECH RECOGNITION RELATED CODE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // const [isLastStep, setIsFinalStep] = useState(false);
    // const [transcriptMessage, setTranscriptMessage] = useState("");
    // const [assistantMessage, setAssistantMessage] = useState("");
    // const [spokenMessage, setSpokenMessage] = useState("");
    
    // const [isListening, setIsListening] = useState(false);
    // // callback for the SpeechRecognition component to toggle the isListening state
    // const toggleIsListening = (bool) => {
    //     setIsListening(bool);
    // };

    // const [transcribedText, setTranscribedText] = useState(''); // used to hold the new transcribed text with which we summarize meal
    // const newTranscribedText = useRef(''); // used to hold the new transcribed text with which we summarize meal
    // useEffect(() => {
    //     loggit('     • Setting new transcribed text: ', transcribedText)
    //     newTranscribedText.current = transcribedText;
    // }, [transcribedText]);
        
    // //@@@@@@@@@@ FOCUS INTO THE TRANSCRIBED TEXT INPUT @@@@@@@@@
    // // const transcribedTextInputRef = useRef(null);   
    // var focusTextInput = useRef(false);
    // const focusTranscribedTextInput = () => {
    //     focusTextInput.current = true; // this will trigger the focusTextInput effect within the speechrecognition component
    // };

    // //@@@@@@@@@@ SPEECH RECOGNITION COMPONENT Activation Controllers @@@@@@@@@@@@@@@@@@@@@@
    // const [isSpeechRecognitionEnabled, setIsSpeechRecognitionEnabled] = useState(false);
    // useEffect(() => {
    //     if (settings.speech_recognition || settings.fudrik_speaks) {
    //         setIsSpeechRecognitionEnabled(true);
    //     } else {
    //         setIsSpeechRecognitionEnabled(false);
    //     }
    // }, [settings.speech_recognition, settings.fudrik_speaks]);


    // //@@@@@@@@@@ T-Minus SPEECH RECOGNITION @@@@@@@@@@
    // const tminusSpeechRecognition = () => {  
    //     // create 3 second countdown timer
    //     var tminusCountdown = 3;
    //     const countItDown = () => {
    //         if (tminusCountdown > 0) {
    //             // setSpokenMessage(tminusCountdown);
    //             setTranscriptMessage(`Active Listening begins in... ${tminusCountdown}`);
    //             tminusCountdown -= 1;
    //             setTimeout(() => {
    //                 countItDown();
    //             }, 1000);
    //         } else {
    //             tminusCountdown = 3;
    //             setTranscriptMessage("What did you eat?");
    //             setIsListening(true);
    //         }
    //     };
    //     countItDown();
    // };
    // //-------------------------- END SPEECH RECOGNITION ------------------------------------------



    // <Modal.Body>
    //     <Container>
    //         <SpeechRecognition 
    //             // props
    //             isEnabled={isSpeechRecognitionEnabled}
    //             isListening={isListening}
    //             focusTextInput={focusTextInput.current}
    //             transcriptMessage={transcriptMessage}
    //             assistantMessage={assistantMessage}
    //             spokenMessage={spokenMessage}
    //             transcribedText={transcribedText}
    //             isLastStep={isLastStep}
    //             // callbacks
    //             handleDisabled={() => setIsSpeechRecognitionEnabled(false)} //callback function to disable speechrecognition
    //             toggleIsListening={toggleIsListening}
    //             setSpokenMessage={setSpokenMessage}
    //             focusTextInputDone={() => focusTextInput.current = false}
    //             setTranscribedText={setTranscribedText}
    //             triggerApiCall={handleMealExtraction} />
    //     </Container>
    // </Modal.Body>