import { loggit, handleApiError } from './helpers';
import { getCookie } from './cookie';

const jwtToken = getCookie('jwtToken');


//_____________________________________________________________
//@@@@@@@@@@ ASSISTANT CONVERSATION @@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiAssistantConversation(conversationShuttle, prompt) {
    const nextPrompt = {
        'role': 'user',
        'content': prompt,
    }
    conversationShuttle.messages.push(nextPrompt); // add the new prompt to the conversation shuttle
    loggit('Request body: ', conversationShuttle);

    try {
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}assistant`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(conversationShuttle),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('     • Assistant Conversation Response data: ', data);

        var assistantMessage = '';
        if (data.messages.length > 1) {  // update the assistant message with the last message in the prompt history
            assistantMessage = data.messages[data.messages.length - 1].content;
        } else {
            assistantMessage = "Apologies. I didn't quite understand that. Please try again.";
        };
        conversationShuttle.messages = data.messages; // replace the conversation shuttle messages with the full prompt history passed back from the API
        conversationShuttle.usabuse = data.usabuse; // update the usabuse counter with the returned value

        return [assistantMessage, conversationShuttle];
    } catch(error) {
        loggit('     • Error getting assistant response1 data:', error);
        alert('Apologies... Something went wrong. Please try again later.');
        return false;
    }
}
// returns the assistant response with the conversation object or false
//------------- END openaiAssistantConversation ----------------------------



//_________________________________________________________________________
//@@@@@@@@@@ GET MEAL BREAKDOWN @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiGetMealBreakdown(conversationShuttle) {
    try {
        // final call to the API to get the meal breakdown and save the meal
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}breakdown`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(conversationShuttle),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('     • Response2 data: ', data);

        const assistantMessage = data.messages[data.messages.length - 1].content; // update the assistant message            

        return assistantMessage;
    } catch (error) {
        console.error('     • Error getting meal_breakdown:', error);
        return false;
    }
}




//_______________________________________________________
//@@@@@@@@@@ GET SEGMENTATION @@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiGetSegmentation(transcriptRequest) {
    try {
        const send_transcript = { 'partial_transcript': transcriptRequest };
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}segmentation`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(send_transcript),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('Segmentation data retrieved: ', data);
        return data;
    } catch(error) {
        loggit('     • Error getting segmentation data:', error);
        alert('Apologies... Something went wrong. Please try again later.');
        return false;
    }
}
// returns the mealSegments object (meal_type, meal_segments (one or more items)) or false
//------------- END openaiGetSegmentation ----------------------------


//_______________________________________________________
//@@@@@@@@@@ GO GET A HAIKU @@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiGetHaiku(breakdown_summary) {
    console.log(`getHaiku called with: `, breakdown_summary);
    let haiku = '';
    try {
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}haiku`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ meal_summary: breakdown_summary }),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('     • haiku response data: ', data.haiku);
        haiku = data.haiku;
    } catch (error) {
        console.error('Error getting haiku:', error);
        haiku = "Ah inspiration!\nIs such a fickle mistress...\nGot nothing today.";
    }
    return haiku
}
// returns the haiku string or false
//------------- END openaiGetHaiku ----------------------------


//_______________________________________________________
//@@@@@@@@@@ LOG THE MEAL @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiLogMeal(body) {
    try {
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}pending/log`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('Meal logged: ', data);
        return true;
    } catch(error) {
        loggit('     • Error logging meal:', error);
        alert('Apologies... Something went wrong. Please try again later.');
        return false;
    }
}
// returns true or false
//------------- END openaiLogMeal ----------------------------



//_____________________________________________________________
//@@@@@@@@@@ NEW TOUCHPOINT CHECKIN @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiTouchpointCheckin(transcript) {
    try {
        const response = await fetch(`${process.env.REACT_APP_TOUCHPOINT_URI}checkin/new`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ checkin_text: transcript }),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('     • Touchpoint Checkin Response data: ', data);
        // response format:
        // {
        //     "_id": 1,
        //     "keywords": [
        //         "bloated",
        //         "gassy",
        //         "tired",
        //         "digestive issues"
        //     ],
        //     "wellness_rating": 60
        // }
        return data;
    } catch(error) {
        loggit('     • Error getting touchpoint checkin response data:', error);
        alert('Apologies... Something went wrong. Please try again later.');
        return false;
    }
}
//---------------- END openaiTouchpointCheckin -------------------


//_____________________________________________________________
//@@@@@@@@@@ DELETE TOUCHPOINT CHECKIN @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function openaiDeleteTouchpointCheckin(checkin_id) {
    try {
        const response = await fetch(`${process.env.REACT_APP_TOUCHPOINT_URI}checkin/delete/${checkin_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
        await handleApiError(response);
        
        return true;
    } catch(error) {
        loggit('     • Error Deleting touchpoint checkin:', error);
        alert('Apologies... Something went wrong. Please try again later.');
        return false;
    }
}
//---------------- END openaiTouchpointCheckin -------------------




