import React, { createContext, useState, useEffect, useContext } from 'react';
import { handleApiError, loggit } from '../utils/helpers';
import { getCookie } from '../utils/cookie';

const SettingsContext = createContext();

// Function to detect mobile device
function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function SettingsProvider({ children }) {
    const [settings, setSettings] = useState(null);
    const [isSettingsLoading, setSettingsLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [isMobile, setIsMobile] = useState(isMobileDevice());

    useEffect(() => {
        async function fetchSettings() {
        const jwtToken = getCookie('jwtToken');
        try {
            const response = await fetch(`${process.env.REACT_APP_FUDRIK_AUTH_URI}account/settings/get/`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            }
            });
            await handleApiError(response);
            const data = await response.json();
            loggit('Site settings retrieved: ', data);
            setSettings(data.settings);
            setSettingsLoading(false);
        } catch(error) {
            loggit('     • Error getting site settings:', error);
        }
        }

        fetchSettings();
    }, []); // Empty dependency array means this effect runs once when the component mounts

    const value = {
        settings,
        updateSettings: (newSettings) => setSettings(prevSettings => ({ ...prevSettings, ...newSettings })),
        isSettingsLoading,
        isMobile, // Include the mobile status in the value
    };

    return (
        <SettingsContext.Provider value={value}>
        {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => useContext(SettingsContext);
