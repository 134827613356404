import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { loggit, handleApiError } from '../utils/helpers';
import { getCookie } from '../utils/cookie';

import * as d3 from 'd3';
import LineChart from './LineChart';

const LineChartContainer = () => {
    const jwtToken = getCookie('jwtToken');
    const days = 28;

    const [loading, setLoading] = useState(true);
    const [nutritionData, setNutritionData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const marginLeft = 40;
    const marginRight = 30;
    const marginTop = 30;
    const marginBottom = 30;
    const width = 1500;
    const height = 420;

    // Define the nutrient categories
    const nutrientCategories = {
        Macronutrients: ['protein', 'totalCarbohydrate', 'totalFat', 'monounsaturatedFat', 'polyunsaturatedFat', 
            'saturatedFat', 'unsaturatedFat', 'transFat', 'dietaryFiber', 'sugar', 'cholesterol'
        ],
        Minerals: [
            'calcium', 'chloride', 'chromium', 'copper', 'iodine', 'iron', 'magnesium', 'manganese', 
            'molybdenum', 'phosphorus', 'potassium', 'selenium', 'sodium', 'zinc'
        ],
        Vitamins: [
            'biotin', 'folate', 'folicAcid', 'niacin', 'pantothenicAcid', 'riboflavin', 'thiamin', 
            'vitaminA', 'vitaminB12', 'vitaminB6', 'vitaminC', 'vitaminD', 'vitaminE', 'vitaminK'
        ]
    };

    // Create a state to hold the selected nutrient category
    const [selectedCategory, setSelectedCategory] = useState(null);
    // Map the nutrient categories to the format required by react-select
    const categoryOptions = Object.keys(nutrientCategories).map(category => ({ value: category, label: category }));
    
    useEffect(() => {
        console.log('selectedCategory: ', selectedCategory);
        // Filter the data based on the selected nutrient category
        let filteredData = [];
        if(nutritionData) {
            filteredData = nutritionData;
            filteredData = filteredData.filter(d => nutrientCategories[selectedCategory].includes(d.nutrient));
            console.log('filteredData: ', filteredData);
        }
        // Set the data to the filtered data
        setFilteredData(filteredData);
        setRefreshKey(oldKey => oldKey + 1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory]);
    
    const getNutritionData = async () => {
        // Get the nutrition totals data from the API endpoint /meals/reports/sum/{days}"
        try {
            const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}reports/nutrition/${days}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                }
            });
            await handleApiError(response);
            const data = await response.json();
            loggit('Nutritional data retrieved: ', data);
            return data;
        } catch(error) {
            loggit('     • Error getting nutritional data:', error);
        }
    };
    useEffect(() => {
        getNutritionData()
            .then(data => {
                setNutritionData(data);
                setFilteredData(data);
                setSelectedCategory('Macronutrients');
                setLoading(false);
            })
            .catch((error) => {
                loggit('     • Error getting nutrition data: ', error);
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <>
        {loading ? (
            // Show a loading spinner or some other placeholder
            <div className='d-flex justify-content-center'>Loading...</div>
        ) : (
            <div key = {refreshKey} className='d-flex flex-column align-items-center'>
                <div className='mt-5 mb-3 fudrik-tagline'>
                    <h2>Nutritional Trends</h2>
                </div>
                <div>


                </div>
                <div className='outer-glow-dark w-100'>
                    <div className='inner-glow-light burndown-profile'>
                        {/* <div className="mt-5 d-flex flex-wrap justify-content-center">
                            <h3 className='text-center fudrik-text-light'>{selectedCategory}</h3>
                        </div> */}
                        <div className="mt-5 d-flex flex-wrap justify-content-center">
                            <Form.Select
                                className='mb-3 outer-glow-light fudrik-dropdown'
                                value={selectedCategory}
                                onChange={event => {
                                    const selectedValue = event.target.value;
                                    if (selectedValue !== "") {
                                    setSelectedCategory(selectedValue);
                                    }
                                }}
                                >
                                <option value="">Select a category</option>
                                {categoryOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center line-chart">
                            {nutritionData && (
                                <LineChart 
                                data={filteredData} 
                                options = {{
                                    x: d => new Date(d.date), // parse date strings into Date objects for D3 to use
                                    y: d => d.value,
                                    z: d => d.nutrient, // categorize by nutrient
                                    // Note that `title`, `defined`, `xDomain`, `yDomain`, `yFormat`, `yLabel`, and `zDomain` 
                                    // need to be defined if you want to use them.
                                    curve: d3.curveNatural, // method of interpolation between points
                                    marginTop: marginTop, // top margin, in pixels
                                    marginRight: marginRight, // right margin, in pixels
                                    marginBottom: marginBottom, // bottom margin, in pixels
                                    marginLeft: marginLeft, // left margin, in pixels
                                    width: width, // outer width, in pixels
                                    height: height, // outer height, in pixels
                                    xType: d3.scaleUtc, // type of x-scale
                                    xRange: [marginLeft, width - marginRight], // [left, right]
                                    yType: d3.scaleLinear, // type of y-scale
                                    yRange: [height - marginBottom, marginTop], // [bottom, top]
                                    color: "#f4b3e5", // stroke color of line, as a constant or a function of *z*
                                    strokeLinecap: null, // stroke line cap of line
                                    strokeLinejoin: null, // stroke line join of line
                                    strokeWidth: 1.5, // stroke width of line
                                    strokeOpacity: null, // stroke opacity of line
                                    mixBlendMode: "normal", // blend mode of lines
                                    voronoi: true, // show a Voronoi overlay? (for debugging)
                                    ticksOn: true
                                }}
                            />)}
                        </div>

                        {/* <div>
                            <Button variant='info' className="button-rounded mb-3 btn-sm outer-glow-light" onClick={() => setRefreshKey(oldKey => oldKey + 1)}>Watch it again!</Button>
                        </div> */}
                        <hr />
                        <div className='mt-4 mb-5 fudrik-text-light'>
                            <div className='d-flex justify-content-center'>
                                <div className='spindle-xplnd text-justify'>
                                    <p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    );

};

export default LineChartContainer;





    