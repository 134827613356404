import { loggit, handleApiError } from './helpers';
import { getCookie } from './cookie';

const jwtToken = getCookie('jwtToken');

//_______________________________________________________
//@@@@@@@@@@ GET Abridged History @@@@@@@@@@@@@@@@@@@@@@@@@@@
export async function getAbridgedHistory(lastDays) {
    try {
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}reports/nutrition/abridged/${lastDays}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            }
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('Abridged Nutritional data retrieved: ', data);
        return data;
    } catch(error) {
        loggit('     • Error getting abridged nutritional data:', error);
    }
}
