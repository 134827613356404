import React from 'react';
import { useSettings } from './SettingsContext';
import FudrikHome from '../components/FudrikHome';

function SettingsPrimer() {
  const { isSettingsLoading } = useSettings();

  if (isSettingsLoading) {
    // Render some loading state
    return <div>Loading settings...</div>;
  }

  // Render the rest of your app
  return <FudrikHome />;
}

export default SettingsPrimer;