import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const DaySpindleChart = ({ index, day, averageHeight, lastDay, maxCalories }) => {
    const { dayOfWeek, dateString, dailyCalories, reserves } = day;
    const ref = useRef();
    const barRef = useRef();
    const containerRef = useRef();
    
    const colorScale = d3.scaleLinear()
        .domain([d3.min(reserves, d => d.burnRate), d3.max(reserves, d => d.burnRate)])
        .range(['#61dafb', '#FF00C3']);
    
    const spindleWidth = 40;
    const spindleHeight = 240;
    const barWidth = 10;
    const barHeight = 200;
    
    const barScale = d3.scaleLinear()
    .domain([0, 4000])
    .range([0, barHeight]);
    
    const flameMargin = 10;
    const flameVariance = dailyCalories - averageHeight;    
    
    // FUEL CELL
    useEffect(() => {
        const svg = d3.select(ref.current);
        svg.selectAll('*').remove(); 

        const yScale = d3.scaleBand()
            .domain(reserves.map((_, i) => i))
            .range([0, spindleHeight])
            .padding(-0.5);

            // .domain([0, d3.max(reserves, d => d.calories)])
        const xScale = d3.scaleLinear()
            .domain([0, maxCalories])
            .range([0, spindleWidth]);

        // Add central vertical line
        svg.append('line')
            .attr('x1', spindleWidth / 2)
            .attr('y1', 0)
            .attr('x2', spindleWidth / 2)
            .attr('y2', spindleHeight)
            .attr('stroke', '#540258')
            .attr('stroke-opacity', lastDay ? 0.3 : 1)
            .attr('stroke-width', 2);

        // Add horizontal lines
        setTimeout(() => {
            svg.selectAll('rect')
                .data(reserves)
                .join('rect')
                .attr('y', (_, i) => yScale(i))
                .attr('x', d => spindleWidth / 2)
                .attr('width', 0)
                .attr('height', yScale.bandwidth())
                .attr('fill', d => colorScale(d.burnRate))
                .transition()
                .duration(1000)
                .delay((d, i) => i * 15) // Delay based on index
                .attr('x', d => (spindleWidth - xScale(d.calories)) / 2)
                .attr('width', d => xScale(d.calories));
        }, 500 + index * 75)

        // Add a time of day marker if this is the last day
        if (lastDay) {
            const updatePosition = () => {
                // Clear previous elements
                svg.select('.time-of-day').remove();
                
                const now = new Date();
                const timeOfDayHours = now.getHours();
                const timeOfDayMinutes = now.getMinutes();
                const timeOfDayPosition = timeOfDayHours * 60 + timeOfDayMinutes;
                
                const tScale = d3.scaleLinear()
                    .domain([0, 1440])
                    .range([0, spindleHeight]);

                // Format timeOfDayHours and timeOfDayMinutes to have leading zeroes
                let formattedHours = String(timeOfDayHours).padStart(2, '0');
                let formattedMinutes = String(timeOfDayMinutes).padStart(2, '0');
                let timeString = `${formattedHours}:${formattedMinutes}`;
        
                // Position for the time string
                let textYPosition = timeOfDayHours > 12 ? tScale(timeOfDayPosition) - 10 : tScale(timeOfDayPosition) + 20;
                
                // Create group for line and text
                let clock = svg.append('g')
                    .attr('class', 'time-of-day');
        
                // Draw the line
                clock.append('line')
                    .attr('x1', 0)
                    .attr('x2', spindleWidth)
                    .attr('y1', tScale(timeOfDayPosition))
                    .attr('y2', tScale(timeOfDayPosition))
                    .attr('stroke', '#FFFFFF')
                    .attr('stroke-width', 2)
                    .attr('stroke-dasharray', '1')
                    .attr('stroke-opacity', 0.5);
        
                // Draw the clock display
                clock.append('text')
                    .attr('x', spindleWidth / 2)
                    .attr('y', textYPosition)
                    .attr('fill', '#FFFFFF')
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '12px')
                    .attr('stroke', 'none')
                    .text(timeString);
            }
        
            // Run initially with delay
            setTimeout(updatePosition, 2500 + index * 75);
            
            // Update every minute
            setInterval(updatePosition, 60000);
        }
            

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reserves]);

    // FLAME
    useEffect(() => {
        const svg = d3.select(barRef.current);
        svg.selectAll('*').remove(); 

        console.log('Max Calories: ', maxCalories);

        svg.append('line')
            .attr('x1', 0)
            .attr('y1', barHeight - barScale(averageHeight) - flameMargin)
            .attr('x2', 0)
            .attr('y2', barHeight - barScale(averageHeight) - flameMargin)
            .attr('stroke', '#540258')
            .attr('stroke-dasharray', '1')
            .attr('stroke-width', 4)
            .transition()
            .duration(1500)
            .delay((d, i) => 5500 - index * 80 )
            .attr('x2', spindleWidth)
    
        setTimeout(() => {
            svg.append('rect')
            .attr('x', (spindleWidth - barWidth) / 2)
            .attr('y', barHeight - 15)  // make it start from the center point
            .attr('width', barWidth)
            .attr('height', 0)  // start from 0 height
            .attr('rx', barWidth / 2)
            // .attr('ry', barWidth / 2)
            .attr('fill', flameVariance > 100 ? '#ffffff' : 'rgb(255, 173, 243)'); // pink if within threshold, white if over daily calories to average calories threshold
            
            svg.select('rect')
            .transition()
            .duration(1200) 
            .attr('y', barHeight - barScale(dailyCalories) - flameMargin - barWidth/2)
            .attr('height', barScale(dailyCalories));
        }, 5000 - index * 80);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dailyCalories, reserves]);



    return (
        <div ref={containerRef} className='spindle-day'>
            <div>
                <svg ref={barRef} width={spindleWidth} height={barHeight}></svg>
            </div>
            <div className='spindle-plot'>
                <svg ref={ref} width={spindleWidth} height={spindleHeight}></svg>
            </div>
            <div className='mb-0 fudrik-text-light small' style={{ textAlign: 'center' }}>{dayOfWeek.substring(0,3)}</div>
            <div className='mb-2 fudrik-text-light small' style={{ textAlign: 'center' }}>{dateString}</div>
        </div>
    );
};

export default DaySpindleChart;

