import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import * as tf from '@tensorflow/tfjs';

const VoiceVisualizerA = ({ isOpen, mediaStream }) => {
    
    const numMelBands = 45;
    const refreshRate = 10; // milliseconds
    const fftSize = 2048;
    const windowSize = fftSize;
    const hopSize = windowSize / 2;
    const analyserMinDecibels = -120; // These are used by the analyserNode to calculate the frequency data
    const analyserMaxDecibels = -15;   // we should research if these values work data-wise, these were chosen for best visualization results
    
    const lowerEdgeHertz = 0;
    const upperEdgeHertz = useRef(null);
    const sampleRate = useRef(null);
    const melFilterbank = useRef(null);
    const melSpectrogramData = useRef(null);
    const analyserNode = useRef(null);
    const isAudioInitialized = useRef(false);

    const hertzToMel = (hertz) => {
        return 2595 * Math.log10(1 + hertz / 700);
    };
    const melToHertz = (mel) => {
        return 700 * (Math.pow(10, mel / 2595) - 1);
    };

    const triangularFilterbank = async () => {
        // create a linearly spaced array of numMelBands points between the lower and upper edge mel values
        const lowerEdgeMel = hertzToMel(lowerEdgeHertz);
        const upperEdgeMel = hertzToMel(upperEdgeHertz.current);
        const melBands = tf.linspace(lowerEdgeMel, upperEdgeMel, numMelBands + 2);
        // convert to an array then convert the mel values back to hertz
        let melBandsArray = await melBands.array();    
        let melCenterpointsHz = melBandsArray.map(mel => melToHertz(mel));
        melBands.dispose();
        melBandsArray = null;
        const filterbank = [];
        for (let i = 1; i < melCenterpointsHz.length - 1; i++) {
            filterbank[i - 1] = new Array(hopSize).fill(0);

            const lower = melCenterpointsHz[i - 1];
            const middle = melCenterpointsHz[i];
            const upper = melCenterpointsHz[i + 1];
            const length = upper - lower;
            const height = 2 / length;
            const leftSlope = height / (middle - lower);
            const rightSlope = height / (middle - upper);
            const herzBinWidth = upperEdgeHertz.current / hopSize ;
            for (let j = 0; j < hopSize; j++) {
                let weight = 0;
                const frequencyHz = j * herzBinWidth; // converting j to a herz value we can use to compare to the mel values (which are also in herz)
                if (frequencyHz <= middle) {
                    weight = leftSlope * (frequencyHz - lower);
                } else {
                    weight = height + (rightSlope * (frequencyHz - middle));
                }
                weight = Math.max(weight, 0);
                filterbank[i - 1][j] = weight;
            };
        }; // filterbank is now a 2d array with weights for applying to hertz values
        // Now we have the triangular filterbank as a constant that we can use on every audio data event to convert the STFT into a mel spectrogram
        console.log('Mel Filterbank: ', filterbank);
        return filterbank;
    };
        

    useEffect(() => {
        if (isOpen && mediaStream && !isAudioInitialized.current) {
            // initiate the AudioContext for the mel spectrogram calculations, must be done on user interaction
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            analyserNode.current = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(mediaStream);
            source.connect(analyserNode.current); // Connect the source to the analyserNode.current
            
            analyserNode.current.fftSize = fftSize;
            analyserNode.current.smoothingTimeConstant = 0.0;
            analyserNode.current.minDecibels = analyserMinDecibels;
            analyserNode.current.maxDecibels = analyserMaxDecibels;
            
            sampleRate.current = audioContext.sampleRate;
            upperEdgeHertz.current = sampleRate.current / 2;
            
            // calculate the triangular filters to convert the linearly spaced mel values to triangular mel bins for the mel spectrogram, so we don't have to do this on every audio data event
            (async () => {
                try {
                    melFilterbank.current = await triangularFilterbank();

                }   catch (error) {
                    console.log('Error calculating mel filterbank: ', error);
                } 
            })();
            // create the setInterval to get the audio data using getVisData every refreshRate (milliseconds)
            const interval = setInterval(() => {
                getVisData();
            }, refreshRate);

            isAudioInitialized.current = true;
            console.log('======================= Audio initialized');
            
            return () => clearInterval(interval);
        } else if (!isOpen || !mediaStream) {
            isAudioInitialized.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, mediaStream]);

    

    const calculateMelSpectrogram = async (frequencyData) => {
            // let minVal;
            let maxVal;
            // convert the frequencyData to a tensor
            let melSpectrogram = [];
            let melEnergy;
            // convert frequencyData to an array with data type float32
            frequencyData = Array.from(frequencyData).map((value) => {
                return value;
            });

            
            // multiply the frequencyData against each array in the mel filterbank to get the Mel Spectrogram
            for (let i = 0; i < melFilterbank.current.length; i++) {
                // check that frequencyData and melFilterbank.current[i] are the same length
                if (frequencyData.length !== melFilterbank.current[i].length) {
                    console.log('<ERROR> frequencyData and melFilterbank.current[i] are not the same length');
                    return;
                } else {
                    // multiply the two arrays, then sum the result to get the mel energy
                    melEnergy = frequencyData.map((frequency, index) => frequency * melFilterbank.current[i][index]);
                    melEnergy = melEnergy.reduce((a, b) => a + b, 0);
                    melSpectrogram.push(melEnergy);
                }
            }
            // Normalize the values to the range 0 to 1...  setting a fixed maxVal to normalize the tensor values across all sampled audio
            // QUESTION: is this a good idea? or should we normalize each audio data event separately? If it is a good idea, will this value work for all audio data events?
            maxVal = 8;
            melSpectrogram = normalizeArray(melSpectrogram, 0, maxVal);
            
            melEnergy = null;

            return melSpectrogram;
    };
    const normalizeArray = (array, min = 0, max = 255) => {
        const normalizedArray = array.map((value) => {
            return (value - min) / (max - min);
        });
        return normalizedArray;
    };

  
    
    const getVisData = async () => {
        // first get the frequency data from the audio data event
        let frequencyData = new Uint8Array(analyserNode.current.frequencyBinCount);
        analyserNode.current.getByteFrequencyData(frequencyData);
        // console.log('    Frequency Data: ', frequencyData)

        // now apply the triangular filterbank to the frequency data then take the logarithm of it to end up with the mel spectrogram data
        melSpectrogramData.current = await calculateMelSpectrogram(frequencyData);
        // console.log('    Mel Spectrogram Data: ', melSpectrogramData.current);
        
        setMelSpectrogramVisData(melSpectrogramData.current); // set the state to trigger the visualization update

        melSpectrogramData.current = null;
        frequencyData = null;
    };



    //___________________________________________________________
    //@@@@@@@@@@ MEL SPECTROGRAM (Horizontal) VISUALIZATION @@@@@@@@@@@@@@@@@@@@@
    const [melSpectrogramVisData, setMelSpectrogramVisData] = useState(null);
    const [spectrogram, setSpectrogram] = useState([]);
    const svgRef = useRef(null);

    const addTimestamps = (spectrogramData) => {
        return {
            data: spectrogramData,
            key: new Date().getTime()
        };
    };
    
    useEffect(() => {
        if (!isOpen || !melSpectrogramVisData || melSpectrogramVisData.length < 1) return;
        // console.log('melSpectrogramVisData: ', melSpectrogramVisData);
        const updatedSpectrogramArray = spectrogram.length === 0 
                                        ? [addTimestamps(melSpectrogramVisData)] 
                                        : [...spectrogram, addTimestamps(melSpectrogramVisData)];
        // console.log('updatedSpectrogramArray: ', updatedSpectrogramArray);
        setSpectrogram(updatedSpectrogramArray);
        
        // Configure parameters
        const blockSize = 5; // Size of each block in pixels
        const blockWidth = blockSize * 2.25;
        // get the current width of the html element containing the svg and divide it by the blocksize to get the number of blocks wide
        const spectrogramWidth = (svgRef.current.getBoundingClientRect().width / blockWidth)*.999;

        // melSpectrogramVisData.shift(); // Remove the first frame (it's all zeros)
        // console.log('svgWidth: ', svgWidth, '     spectrogram.length: ', spectrogram.length);
        
        if (spectrogram.length > spectrogramWidth) {
            const updatedSpectrogram = [...spectrogram];
            updatedSpectrogram.shift();
            setSpectrogram(updatedSpectrogram);
        }
        const svgHeight = 40 * blockSize;

        const colorScale = d3.scaleSequential(d3.interpolateInferno)
            .domain([0, 1]); 
    
        const svg = d3.select(svgRef.current)
            .attr('height', svgHeight); // set the height dynamically based on the number of frequency bins

        // Update visualization
        const updateVisualization = () => {
            // Bind the spectrogram data to the frames
            const frames = svg.selectAll('.frame')
                .data(spectrogram, d => d.key) // Use the timestamp key we added for binding

            // Enter new frames
            frames.enter()
                .append('g')
                .attr('class', 'frame')
                .attr('transform', (_, i) => `translate(${i * blockWidth}, 0)`)
                .selectAll('rect')
                .data(d => [...d.data].reverse())
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', (_, i) => i * blockSize)
                .attr('width', blockWidth)
                .attr('height', blockSize)
                .attr('fill', d => colorScale(d));

            // Update existing frames
            frames.attr('transform', (_, i) => `translate(${i * blockWidth}, 0)`);

            // Remove old frames
            frames.exit().remove();
        };
    
        updateVisualization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, melSpectrogramVisData]);  
    //___________________________________________________________


    if (!mediaStream) {
        return  <div className='fudrik-text-light'>Audio Visualiser standing by...</div>;
    };

    return <svg ref={svgRef} width="100%" height="100%" style={{backgroundColor: 'black'}}/>;
};

export default VoiceVisualizerA;
