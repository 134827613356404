import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { loggit, getBreakdownMessage } from '../utils/helpers';
import { openaiAssistantConversation, openaiGetMealBreakdown, openaiGetHaiku } from '../utils/openai';
import { useSettings } from '../settings/SettingsContext';

import HaikuModal from '../modals/HaikuModal';

import SpeechRecognition from '../components/SpeechRecognition';
// import MealSegmentsList from '../components/MealSegmentsList';



const LogMealModal = ({ isOpen, onClose, loggingMealType }) => {
    const {settings, isMobile } = useSettings();
    const isThisModalOpen = useRef(false);
    
    const [haikuModalOpen, setHaikuModalOpen] = useState(false);
    const haiku = useRef("");
    
    
    //_____________________________________________________________________________________
    //@@@@@@@@@@ SPEECH RECOGNITION RELATED CODE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [conversationModeOverride, setConversationModeOverride] = useState(isMobile ? true : false); // used to override the conversation mode in the SpeechRecognition component
    //eslint-disable-next-line
    const [isLastStep, setIsLastStep] = useState(false);
    const [transcriptMessage, setTranscriptMessage] = useState("");
    const [assistantMessage, setAssistantMessage] = useState("");
    const [spokenMessage, setSpokenMessage] = useState("");
    
    const [isProcessBarActivated, setIsProcessBarActivated] = useState(false);
    const [processBarMessage, setProcessBarMessage] = useState("");
    const [isListening, setIsListening] = useState(false);
    // callback for the SpeechRecognition component to toggle the isListening state
    const toggleIsListening = (bool) => {
        setIsProcessBarActivated(bool);
        setIsListening(bool);
        if (bool) {
            setProcessBarMessage("Go ahead. I'm Listening...");
        } else {
            setProcessBarMessage('');
        }
    };

    const [transcribedText, setTranscribedText] = useState(''); // used to hold the new transcribed text with which we summarize meal
    const newTranscribedText = useRef(''); // used to hold the new transcribed text with which we summarize meal
    useEffect(() => {
        loggit('     • Setting new transcribed text: ', transcribedText)
        newTranscribedText.current = transcribedText;
    }, [transcribedText]);
        
    //@@@@@@@@@@ FOCUS INTO THE TRANSCRIBED TEXT INPUT @@@@@@@@@
    // const transcribedTextInputRef = useRef(null);   
    var focusTextInput = useRef(false);
    const focusTranscribedTextInput = () => {
        focusTextInput.current = true; // this will trigger the focusTextInput effect within the speechrecognition component
    };

    //@@@@@@@@@@ SPEECH RECOGNITION COMPONENT Activation Controllers @@@@@@@@@@@@@@@@@@@@@@
    const [isSpeechRecognitionEnabled, setIsSpeechRecognitionEnabled] = useState(false);
    useEffect(() => {
        if (settings.speech_recognition || settings.fudrik_speaks) {
            setIsSpeechRecognitionEnabled(true);
        } else {
            setIsSpeechRecognitionEnabled(false);
        }
    }, [settings.speech_recognition, settings.fudrik_speaks]);


    //@@@@@@@@@@ T-Minus SPEECH RECOGNITION @@@@@@@@@@
    const tminusSpeechRecognition = () => {  
        // create 3 second countdown timer
        var tminusCountdown = 3;
        const countItDown = () => {
            if (tminusCountdown > 0) {
                // setSpokenMessage(tminusCountdown);
                setTranscriptMessage(`Active Listening begins in... ${tminusCountdown}`);
                tminusCountdown -= 1;
                setTimeout(() => {
                    countItDown();
                }, 1000);
            } else {
                tminusCountdown = 3;
                setTranscriptMessage("What did you eat?");
                toggleIsListening(true);
            }
        };
        countItDown();
    };
    //-------------------------- END SPEECH RECOGNITION ------------------------------------------


    //_____________________________________________________________________________________
    //@@@@@@@@@@ MANAGE THE STATE OF THE CONVERSATION SHUTTLE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const conversation_shuttle = useRef({
        'usabuse': 0,
        'meal_type': '',
        'messages': []
    });
    useEffect(() => {
        console.log('conversation_shuttle.current.meal_type changed: ', loggingMealType);
        conversation_shuttle.current.meal_type = loggingMealType;
    }, [loggingMealType]);
    
    //@@@@@@@@@@ RESET THE CONVERSATION SHUTTLE WHEN THE MODAL CLOSES @@@@@@@@@@@@@@@
    const resetConversationShuttle = () => {
        conversation_shuttle.current = {
            'usabuse': 0,
            'meal_type': '',
            'messages': []
        };
        loggit('conversation_shuttle was reset: ', conversation_shuttle.current);
    };
    //------------------------ END Conversation Shuttle stuffs ----------------------------
    


    //_____________________________________________________________________________________
    //@@@@@@@@@@ TOGGLE THE MEAL EXTRACTION @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [isFetching, setIsFetching] = useState(false);
    const toggleApiCalling = (bool) => {
        if (bool) {
            loggit('     • Toggling meal extraction BEGINS - setting isListening to "false".');
            setIsFetching(true);
            toggleIsListening(false);
        } else {
            loggit('     • Toggling meal extraction ENDS.');
            setIsFetching(false);
            setTranscribedText(''); // reset the transcribed text in this and child components
            if (settings.fudrik_speaks && !isLastStep && !isMobile) toggleIsListening(true); // if fudrik_speaks is enabled, and we're not on the last step, and we're not on mobile, then automatically toggle listening back on
        }
    };
    //---------------------- END toggleApiCalling ---------------------------------------
    
    

    //_____________________________________________________________________________________
    //@@@@@@@@@@ HANDLE THE MEAL EXTRACTION FROM API @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleApiCall = async (transcription) => {
        loggit('>>>>> handleApiCall called for meal type: ', loggingMealType);

        
        if (isThisModalOpen.current && transcription) {
            toggleApiCalling(true); 
            
            setTranscriptMessage('Hang tight...');
            setAssistantMessage('Processing message: "' + newTranscribedText.current + '"');
    
            setTranscribedText(''); // reset it now that we've used it
            loggit("Calling API using transcript: ", transcription)

            try {
                // ASSISTANT CONVERSATION ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                let [assistantMessage1, conversationShuttle] = await openaiAssistantConversation(conversation_shuttle.current, transcription);
                conversation_shuttle.current = conversationShuttle; // update the conversation shuttle with the returned value

                // if MEAL_BREAKDOWN is present... last step in the conversation. Let user know they're done.
                if (assistantMessage1.includes('MEAL_BREAKDOWN')) {
                    loggit(`Final step in conversation. Meal breakdown is present. FudrikSpeaks is ${settings.fudrik_speaks}`);
                    setIsLastStep(true);
                    
                    setTranscriptMessage('"Thy meal be done!! \n\n...almost."');
                    setAssistantMessage("I'm generating nutritional insights for you now. Please don't close this window, but feel free to go do other things.");
                    if (settings.fudrik_speaks) {
                        loggit('     • Setting spoken message.');
                        setSpokenMessage(getBreakdownMessage());
                    };

                    // HAIKU GENERATION ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                    var breakdown_summary = assistantMessage1.split('MEAL_BREAKDOWN')[1].trim();
                    var response_haiku = await openaiGetHaiku(breakdown_summary);
                    
                    // show the haiku while they wait - if it was successfully generated
                    if (response_haiku) {
                        haiku.current = response_haiku;
                        setHaikuModalOpen(true);
                    };
                    
                    // MEAL BREAKDOWN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                    let assistantMessage2 = await openaiGetMealBreakdown(conversation_shuttle.current);
                    
                    if (assistantMessage2.includes('Your meal has been successfully logged!')) {
                        setTranscriptMessage(`"${assistantMessage2}"`);
                        setAssistantMessage('');
                        toggleApiCalling(false);
                        // set a timer to close the modal after 3 seconds and reload the page
                        setTimeout(() => {
                            onClose();
                            window.location.reload();
                            }, 5000);
                    } else {
                        setAssistantMessage('');
                        toggleApiCalling(false);
                    }
                } else {
                    setTranscriptMessage(`"${assistantMessage1}"`);
                    setAssistantMessage('');

                    if (!settings.speech_recognition) focusTranscribedTextInput();   // focus into the transcribed text input if not using  speech recognition
                    
                    if (conversation_shuttle.current.usabuse <= 3) {
                        if (settings.fudrik_speaks) {
                            setSpokenMessage(assistantMessage1);
                        } else {
                            toggleApiCalling(false);
                        }
                    } else {
                        alert(assistantMessage1);
                        onClose();
                    }
                };
            }
            catch (error) {
                console.error('Error extracting meal breakdown:', error);
                alert('Apologies... Something went wrong. Please try again later.');
                setTimeout(() => {
                    onClose();
                    window.location.reload();
                }, 500);
                toggleApiCalling(false);
            }
        } else {
            loggit('No prompt provided, or modal is no longer open. Skipping API call.');
            setTranscriptMessage("Go ahead, I'm listening...");
            setAssistantMessage('Apologies... Could you please repeat that?');
            toggleApiCalling(false); 
        }
    };
    

    
    //@@@@@@@@@@ ON OPEN MODAL ACTIONS @@@@@@@@@@
    const handleOpenLogMealModal = () => {
        isThisModalOpen.current = true;
        setTranscriptMessage("What did you eat?");
        setAssistantMessage("Greetings and salutations! I'll be helping you log your meal today. Just describe what you've eaten in a normal conversational manner.");
        
        setIsSpeechRecognitionEnabled(true);    
        if (settings.fudrik_speaks && !isMobile) {
            tminusSpeechRecognition(); // start the speech recognition in 3... 2... 1...beeeeeeeep
        } else {
            if (!settings.speech_recognition) focusTranscribedTextInput();   // focus into the transcribed text input
        }; //  otherwise, we wait for manual activation with buttons
        setIsFetching(false);
        toggleIsListening(false);
        setIsLastStep(false);
    };
    
    
    //@@@@@@@@@@ ON CLOSE MODAL ACTIONS @@@@@@@@@@
    const handleCloseLogMealModal = async () => {
        isThisModalOpen.current = false;
        loggit('Pre-closing conversation shuttle: ', conversation_shuttle.current);
        
        // close up the modal, reset the state, and stop the recording
        resetConversationShuttle();

        toggleIsListening(false);
        setIsSpeechRecognitionEnabled(false);        

        setSpokenMessage('');
        setTranscribedText('');

        // stop any ongoing speech and clear the queue
        if (settings.fudrik_speaks) {
            if (typeof window !== "undefined" && window.speechSynthesis) {
                loggit('Stopping speech synthesis...clearing the queue.');
                window.speechSynthesis.cancel();
            }
        }
        
        onClose();
    };
    

    
    if (!isOpen) return null;
    
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ RENDER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    return (
        <Modal show 
        onHide={handleCloseLogMealModal}
        onEntered={handleOpenLogMealModal} 
        onExit={handleCloseLogMealModal}
        size="lg"
        className="top-aligned-modal blurred-modal" 
        centered>
        
        <HaikuModal isHaikuOpen={haikuModalOpen} haiku={haiku.current} onClose={() => setHaikuModalOpen(false)} />
        
        <Modal.Header closeButton>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Modal.Title>Füdrik Assistant</Modal.Title>
                <Button variant="secondary" className={`me-2 modal-cancel-button btn-sm`} onClick={handleCloseLogMealModal} >Cancel</Button>
            </div>
        </Modal.Header>
                <SpeechRecognition 
                    // props
                    isEnabled={isSpeechRecognitionEnabled}
                    isListening={isListening}
                    processBarActivated={isProcessBarActivated}
                    processBarMessage={processBarMessage}
                    focusTextInput={focusTextInput.current}
                    transcriptMessage={transcriptMessage}
                    assistantMessage={assistantMessage}
                    spokenMessage={spokenMessage}
                    transcribedText={transcribedText}
                    isFetching={isFetching}
                    isLastStep={isLastStep}
                    conversationModeOverride={conversationModeOverride}
                    // callbacks
                    handleDisabled={() => setIsSpeechRecognitionEnabled(false)} //callback function to disable speechrecognition
                    toggleIsListening={toggleIsListening}
                    setSpokenMessage={setSpokenMessage}
                    focusTextInputDone={() => focusTextInput.current = false}
                    setTranscribedText={setTranscribedText}
                    triggerApiCall={handleApiCall} 
                    toggleApiCalling={toggleApiCalling}
                    setConversationModeOverride={setConversationModeOverride}
                    />
        </Modal>

    );
};

export default LogMealModal;











