import { useState, useEffect, useRef } from 'react';
import { loggit } from '../utils/helpers';


const SpeechSynthesis = ( { text, onSpeechEnd, isFudrikSpeakEnabled } ) => {
    const [utterance, setUtterance] = useState(null);

    //@@@@@@@@@@ CHECK IF MOBILE DEVICE - we handle things differently on phone browsers @@@@@@@@@@@@@@@
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const onSpeechEndRef = useRef();
    if (isFudrikSpeakEnabled) onSpeechEndRef.current = onSpeechEnd; 

    // Available voice profiles
    const availableVoiceProfiles = [
        {
            displayName: "UK Male - Low",
            name: "Google UK English Male",
            voiceIndex: 51,
            rate: 1.05,
            pitch: 0.75,
            lang: 'en-UK',
        },
        {
            displayName: "UK Male - High",
            name: "Google UK English Male",
            voiceIndex: 51,
            rate: 1.05,
            pitch: 1.15,
            lang: 'en-UK',
        },
        {
            displayName: "UK Female",
            name: "Google UK English Female",
            voiceIndex: 50,
            rate: 1.01,
            pitch: 0.9,
            lang: 'en-UK',
        },
        {
            displayName: "US Female",
            name: "Google US English",
            voiceIndex: 49,
            rate: 1.015,
            pitch: 0.98,
            lang: 'en-US',
        },
        { // for mobile android Female
            displayName: "English AU",
            name: "English Australia",
            voiceIndex: 9,
            rate: 1.00,
            pitch:  1,
            lang: 'en-AU',
        },
        { // for mobile android Female
            displayName: "English UK",
            name: "English United Kingdom",
            voiceIndex: 10,
            rate: 1.0,
            pitch: 1.0,
            lang: 'en-US',
        },
        { // for mobile android
            displayName: "English US",
            name: "English United States",
            voiceIndex: 13,
            rate: 1.05,
            pitch: 0.9,
            lang: 'en-GB',
        },
    ];

    const getVoiceProfileFromCookie = () => {
        if (isFudrikSpeakEnabled) {  // Function to get the saved voice profile from cookie
          // For now, we're returning the default voice profile
            if (isMobileDevice()) {
                return availableVoiceProfiles[5];
            } else {
                return availableVoiceProfiles[0];
            }
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined" && window.speechSynthesis) {
          if (window.speechSynthesis.onvoiceschanged !== undefined) {
            // Chrome gets the voices asynchronously.
            window.speechSynthesis.onvoiceschanged = getVoiceProfileFromCookie;
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
      

    useEffect(() => {
        if (isFudrikSpeakEnabled && text && text.length > 0) {
            loggit('SpeechSynthesis useEffect - new text detected');
            const voiceProfile = getVoiceProfileFromCookie();
            const newUtterance = new SpeechSynthesisUtterance(text);
            setVoiceProfile(newUtterance, voiceProfile);
            setUtterance(newUtterance);
            
            newUtterance.onend = () => {
                loggit('SpeechSynthesis onend');
                if (typeof onSpeechEndRef.current === 'function') {
                    console.log('SpeechSynthesis onend - onSpeechEnd is a function');
                    onSpeechEndRef.current();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);
  
    const setVoiceProfile = (utterance, voiceProfile) => {
        if (isFudrikSpeakEnabled) {
            if (utterance) {
                speechSynthesis.cancel();
            }
            loggit('SpeechSynthesis setVoiceProfile using voiceProfile: ', voiceProfile.name);
            const voices = speechSynthesis.getVoices();
            console.log('SpeechSynthesis available voices: ', voices.length);
            const selectedVoice = voices.find((voice) => voice.name === voiceProfile.name);
            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                // Set default voice if selected voice is not found
                console.warn(`Selected voice "${voiceProfile.voice}" not found. Using default voice.`);
                utterance.voice = voices[0];
            }
            utterance.voiceURI = voiceProfile.voiceURI || 'native';
            utterance.volume = voiceProfile.volume || 1; // 0 to 1
            utterance.rate = voiceProfile.rate || 1; // 0.1 to 10
            utterance.pitch = voiceProfile.pitch || 0.8; //0 to 2
            utterance.lang = voiceProfile.lang || 'en-US';
        }
    };


    const speak = () => {
        if (isFudrikSpeakEnabled) {
          loggit('SpeechSynthesis speak')
          if (utterance) {
              speechSynthesis.speak(utterance);
          }
        }
    };

    // Call speak function after setting utterance and voice profile
    useEffect(() => {
        if (isFudrikSpeakEnabled) {
          loggit('SpeechSynthesis useEffect - new utterance detected');
          if (utterance) {
              speak();
          }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utterance]);

    return null; // As it doesn't render anything in the DOM
    };

export default SpeechSynthesis;




// The SpeechSynthesisUtterance interface does include the properties you've listed:

// voice: This is a SpeechSynthesisVoice that represents the voice the utterance will use for speaking.
// voiceURI: This is the URI of the speech synthesis service that the web app should use.
// volume: This is a float representing the volume that the utterance will be spoken at, on a scale from 0 to 1.
// rate: This is the speed at which the utterance will be spoken at, on a scale from 0.1 to 10.
// pitch: This is the pitch at which the utterance will be spoken at, on a scale from 0 to 2.
// text: This is the text that will be synthesized when the utterance is spoken.
// lang: This is the language that will be used to synthesize the utterance.
// So, yes, these are the basic settings you can adjust in the SpeechSynthesisUtterance object. In addition to these, there are also some event handler properties that can be set:

// onstart: An event handler that runs when this utterance has begun to be spoken.
// onend: An event handler that runs when this utterance has completed being spoken.
// onerror: An event handler that runs when an error occurs that prevents this utterance from being spoken.
// onpause: An event handler that runs when this utterance has been paused.
// onresume: An event handler that runs when this utterance has resumed after being paused.
// onboundary: An event handler that runs when the spoken utterance reaches a word or sentence boundary.
// These event handlers can be used to trigger other actions in your application based on the state of the speech synthesis system.









