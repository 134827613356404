import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const LineChart = ({ data, options }) => {
    const ref = useRef();
  
    useEffect(() => {
        let {
            x,
            y,
            z,
            title,
            defined,
            curve,
            marginTop,
            marginRight,
            marginBottom,
            marginLeft,
            width,
            height,
            xType,
            xDomain,
            xRange,
            yType,
            yDomain,
            yRange,
            yFormat,
            yLabel,
            zDomain,
            color,
            strokeLinecap,
            strokeLinejoin,
            strokeWidth,
            strokeOpacity,
            mixBlendMode,
            voronoi,
            ticksOn,
        } = options;

        // Compute values.
        const X = d3.map(data, x);
        const Y = d3.map(data, y);
        const Z = d3.map(data, z);
        const O = d3.map(data, d => d);
        if (defined === undefined)
        defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
        const D = d3.map(data, defined);
        
        // Compute default domains, and unique the z-domain.
        if (xDomain === undefined) xDomain = d3.extent(X);
        if (yDomain === undefined)
        yDomain = [d3.min(Y), d3.max(Y)]; // Use min and max values from the standardized data
        if (zDomain === undefined) zDomain = Z;
        zDomain = new d3.InternSet(zDomain);
        
        // Omit any data not present in the z-domain.
        const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

        // Construct scales and axes.
        const xScale = xType(xDomain, xRange);
        const yScale = yType(yDomain, yRange);
        const xAxis = d3
            .axisBottom(xScale)
            .ticks(width / 80)
            .tickSizeOuter(0);
        const yAxis = d3
            .axisLeft(yScale)
            .ticks(height / 500, yFormat)
            .tickPadding(10); // Add extra padding for tick labels
    
        // Compute titles.
        const T =
            title === undefined ? Z : title === null ? null : d3.map(data, title);
    
        // Construct a line generator.
        const line = d3
            .line()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y(i => yScale(Y[i]) + marginTop);
        
        // Create the SVG element.
        const svg = d3
            .select(ref.current)
            .attr("width", width)
            .attr("height", height + marginTop + marginBottom) // Adjust height to include margins
            .attr("viewBox", [0, 0, width, height + marginTop + marginBottom])
            .attr(
            "style",
            "max-width: 100%; height: auto; height: intrinsic;"
            )
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter", pointerentered)
            .on("pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());
    
        // An optional Voronoi display (for fun).
        if (voronoi)
            svg
            .append("path")
            .attr("fill", "rgba(255, 255, 255, 0.007)")
            .attr("stroke", "rgba(255, 255, 255, 0.04)")
            .attr("d", d3.Delaunay.from(I, i => xScale(X[i]), i => yScale(Y[i])).render());
        
        // Add the x-axis.    
        svg
            .append("g")
            .attr("transform", `translate(0,${height + marginTop})`) // Adjust y-axis position
            .call(xAxis)
            .attr('fill', color);
        
        // tick marks and lines
        if (ticksOn)
            svg
                .append("g")
                .attr("transform", `translate(${marginLeft},${marginTop})`) // Adjust y-axis position
                .call(yAxis)
                .call(g => g.select(".domain").remove())
                .call(g => g.selectAll(".tick line")
                    .clone()
                    .attr("x2", width - marginLeft - marginRight)
                    .attr("stroke-opacity", 1)
                    .style("mix-blend-mode", "normal")
                )
                .call(g => g.append("text")
                    .attr("x", -marginLeft)
                    .attr("y", -marginTop) // Adjust y-axis label position
                    .attr("fill", color)
                    .attr("text-anchor", "start")
                    .text(yLabel)
                );
  
        // Draw the lines
        const path = svg
            .append("g")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
            .selectAll("path")
            .data(d3.group(I, i => Z[i]))
            .join("path")
            .style("mix-blend-mode", mixBlendMode)
            .attr("d", ([, I]) => line(I));
    
        const dot = svg.append("g").attr("display", "none");
    
        dot.append("circle").attr("r", 2.5);
    
        dot
            .append("text")
            .attr("font-family", "sans-serif")
            .attr("font-size", 24)
            .attr("font-weight", "bold")
            .attr("fill", "white")
            .attr("text-anchor", "right")
            .attr("x", 20)
            .attr("y", -20);
    
        function pointermoved(event) {
            const [xm, ym] = d3.pointer(event);
            const i = d3.least(I, i => Math.hypot(xScale(X[i]) - xm, yScale(Y[i]) - ym)); // closest point
            // Calculate the average of the values in the last portion of the data window to get TRENDS
            const lineSpecificData = data.filter(d => d.nutrient === Z[i]);  // This assumes that 'group' is the attribute differentiating lines
            const lastSectionStartIndex = Math.floor(lineSpecificData.length * 1 / 2);
            const lastSectionValues = lineSpecificData.slice(lastSectionStartIndex).map(d => d.value);
            const averageLastSection = lastSectionValues.reduce((acc, value) => acc + value, 0) / lastSectionValues.length;
            const trend = averageLastSection > 0 ? "↑" : "↓";
            
            const movingAverageData = calculateMovingAverage(lineSpecificData, 3); // calculate 5-point moving average
            
            
            // Compute the minimum and maximum values of the nutrient
            const nutrientMin = d3.min(lineSpecificData, d => d.value) * 0.25;
            const nutrientMax = d3.max(lineSpecificData, d => d.value) * 0.25;

            // console.log('averageLastSection: ', averageLastSection);
            // console.log('nutrientMin: ', nutrientMin);
            // console.log('nutrientMax: ', nutrientMax);

            // Remove any existing smoothed line
            svg.selectAll(".smoothed").remove();

            // Add new smoothed line
            svg.append("path")
                .datum(movingAverageData)
                .attr("class", "smoothed")
                .attr("fill", "none")
                .attr("stroke", color)  // You can change the color
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                .curve(d3.curveNatural)
                .x(function(d) { return xScale(d.date) })
                .y(function(d) { return yScale(d.value) + marginTop })
                );
        
            // Define the color scale
            const colorScale = d3.scaleLinear()
                        .domain([nutrientMin, 0, nutrientMax]) 
                        .range(['#61dafb', '#FF00C3']); 
        
            path
                .style("stroke", ([z]) => (Z[i] === z ? null : "#540258"))
                .filter(([z]) => Z[i] === z)
                .raise();
        
            dot.attr("transform", `translate(${xScale(X[i])},${yScale(Y[i])})`);
            if (T) dot.select("text").text(T[i] + " " + trend);
            svg.property("value", O[i]).dispatch("input", { bubbles: true });
        
        
            // Set the highlighted line color
            path
                .style("stroke", ([z]) => (Z[i] === z ? colorScale(averageLastSection)  : "#540258")) 
                .style("stroke-width", ([z]) => (Z[i] === z ? strokeWidth * 8 : strokeWidth ));
        }
            
    
        function pointerentered() {
            path
                .style("mix-blend-mode", null)
                // .style("stroke", null);
            dot
                .attr("display", null);
        }
    
        function pointerleft() {
            path
                .style("mix-blend-mode", mixBlendMode)
                .style("stroke", null)
                .style("stroke-width", strokeWidth);
            dot
                .attr("display", "none");
            svg
                .node().value = null;
            svg
                .dispatch("input", { bubbles: true });
            
            svg.selectAll(".smoothed").remove();  // Remove the smoothed line
        }

        // HELPER FUNCTION to calculate the moving average - smoothed out line to illustrate trends in the data
        function calculateMovingAverage(data, windowSize) {
            let movingAverages = [];
            let paddedData = [...Array(windowSize).fill(data[0]), ...data, ...Array(windowSize).fill(data[data.length - 1])];
        
            for (let i = windowSize; i < paddedData.length - windowSize; i++) {
                let windowData = paddedData.slice(i - windowSize, i + windowSize + 1);
                let sum = windowData.reduce((accumulator, point) => accumulator + point.value, 0);
                let avg = sum / (windowSize * 2 + 1);
                movingAverages.push({ ...data[i - windowSize], value: avg });
            }
        
            return movingAverages;
        }
        

        // LEGEND
        // Get unique nutrient names for the legend
        const nutrientNames = [...new Set(data.map(d => d.nutrient))];
        
        var defs = svg.append("defs");

        var filter = defs.append("filter")
            .attr("id", "dropshadow")
            .attr("height", "130%"); // adjust the height to ensure the shadow is visible

        filter.append("feGaussianBlur")
            .attr("in", "SourceAlpha")
            .attr("stdDeviation", 5)
            .attr("result", "blur");

        filter.append("feOffset")
            .attr("in", "blur")
            .attr("dx", 1)
            .attr("dy", 1)
            .attr("result", "offsetBlur");

        var feFlood = filter.append("feFlood")
            .attr("flood-color", "#240039")
            .attr("flood-opacity", 0.4)
            .attr("result", "colorBlur"); 

        filter.append("feComposite")
            .attr("in", "colorBlur")
            .attr("in2", "offsetBlur")
            .attr("operator", "in")
            .attr("result", "shadow");

        var feMerge = filter.append("feMerge");

        feMerge.append("feMergeNode")
            .attr("in", "shadow")
        feMerge.append("feMergeNode")
            .attr("in", "SourceGraphic");

        // Create a legend group
        const legend = svg.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 22)
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
            .attr("fill", "white")
            .style("filter", "url(#dropshadow)")
            .selectAll("g")
            .data(nutrientNames)
            .join("g")
                .attr("class", (d) => `legend-${d}`) // Assign a class for each legend
                .attr("transform", (d, i) => `translate(-20,${i * 25})`);

        // Append nutrient names to the legend
        legend.append("text")
            .attr("x", 20)
            .attr("y", 9.5)
            .attr("dy", "0.32em")
            .text(d => d);
        
        // function highlightLine(nutrient) {
        //     // Highlight the line corresponding to the nutrient
        //     path
        //         .style("stroke", ([z]) => (z === nutrient ? null : "#540258"))
        //         .filter(([z]) => z === nutrient)
        //         .raise();
        // }
        
        // function resetLine() {
        //     // Reset the line highlight
        //     path
        //         .style("stroke", "#540258");
        // }

        // legend.on("mouseover", function(event, d) {
        //     highlightLine(d);
        // });
        
        // legend.on("mouseout", function(event, d) {
        //     resetLine();
        // });
        

        // // Add mouseover and mouseout events
        // legend
        // .on("mouseover", function(event, d) {
        //     // Highlight the corresponding line
        //     path
        //         .style("stroke", ([z]) => (z === d ? null : "#ddd"))
        //         .filter(([z]) => z === d)
        //         .raise();
        // })
        // .on("mouseout", function(event, d) {
        //     // Reset the line colors
        //     path.style("stroke", ([z]) => (Z[i] === z ? colorScale(averageLastSection)  : "#540258"))
        // });

    }, [data, options]);
  
    return <svg ref={ref}></svg>;
  };
  
  export default LineChart;
  