import React, { useEffect, useState, useRef } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { loggit, handleApiError } from '../utils/helpers';
import { getCookie } from '../utils/cookie';

import ProfileModal from '../modals/ProfileModal';
import DaySpindleChart from './DaySpindleChart';

const BurndownSpindleGraph = () => {
    const jwtToken = getCookie('jwtToken');

    const [isProfileModalOpen, setProfileModalOpen] = useState(false);
    const [caloricBurndown, setCaloricBurndown] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);


    // Create Timeframe selection process and re-load/watch animation handling
    const timeframe = useRef(1); // default to 2 weeks
    const timeframeOptions = {
        '1 week': 1,
        '2 weeks': 2,
        '3 weeks': 3,
        '4 weeks': 4,
        '5 weeks': 5,
        '6 weeks': 6
    };
    const [selectedTimeframe, setSelectedTimeframe] = useState('1 week');

    const handleTimeSelect = (key) => {
        setSelectedTimeframe(key);
        timeframe.current = timeframeOptions[key];
    };
    const handleWatchAgain = () => {
        getCaloricBurndown().then(data => setCaloricBurndown(data));
    };
    const replayAnimation = () => {
        // Trigger a re-render by incrementing the refreshKey (set within the SpindleChart component)
        setRefreshKey(oldKey => oldKey + 1);
        console.log('Triggering re-render of SpindleChart components')
    };

    
    
    const getCaloricBurndown = async () => {
        // Get the calorie burndown data from the API endpoint /meals/burndown/{days}"
        try {
            const days = timeframe.current * 7;
            const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}reports/burndown/${days}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                }
            });
            await handleApiError(response);
            const data = await response.json();
            loggit('Calorie burndown data retrieved: ', data);
            replayAnimation(); // trigger a re-render of the SpindleChart components
            return data;
        } catch(error) {
            loggit('     • Error getting calorie burndown data:', error);
        }
    };
    
    useEffect(() => {
        getCaloricBurndown().then(data => setCaloricBurndown(data));
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openProfileModal = () => {
        setProfileModalOpen(true);
    };
    
    const closeProfileModal = () => {
        setProfileModalOpen(false);
    };

    return (
        <>
        {loading ? (
            // Show a loading spinner or some other placeholder
            <div className='d-flex justify-content-center'>Loading...</div>
        ) : (
            <div key = {refreshKey} className='d-flex flex-column align-items-center'>
                <div className='mt-5 mb-3 fudrik-tagline'>
                    <h2>Caloric Burndown Profile</h2>
                </div>
                <div className='outer-glow-dark'>
                    <div className='inner-glow-light burndown-profile'>
                        <div className="d-flex flex-wrap justify-content-center spindle-graph">
                            {caloricBurndown ? caloricBurndown.map((day, i) => (
                                <DaySpindleChart 
                                    key={i} 
                                    index={i} 
                                    day={day} 
                                    averageHeight={caloricBurndown[0].dailyCalories}
                                    lastDay={i === caloricBurndown.length - 1}
                                    maxCalories={Math.max(...caloricBurndown.map(day => Math.max(...day['reserves'].map(reserve => reserve['calories']))))}
                                />
                            )) : null}
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Dropdown onSelect={handleTimeSelect}>
                                <Dropdown.Toggle className="button-rounded button-cold mb-3 btn-sm outer-glow-light" id="dropdown-basic">
                                    {selectedTimeframe}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {Object.keys(timeframeOptions).map(key => (
                                        <Dropdown.Item eventKey={key} key={key}>{key}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button className="button-rounded button-hot mb-3 btn-sm outer-glow-light roomy-button" onClick={handleWatchAgain}>Watch it again!</Button>
                        </div>
                        <hr />
                        <div className='mt-4 mb-3 fudrik-text-light'>
                            <h3 className='text-center'>What am I looking at?</h3>
                            <div className='d-flex justify-content-center'>
                                <div className='spindle-xplnd text-justify'>
                                    <p>Our bodies are machines... and machines need fuel. Every time we eat, we're adding to our fuel cell and throughout the day we burn off that fuel.
                                        Visualizing our patterns of refueling and consumption helps us to better understand our habits and ultimately start to associate those habits with our experience - how we feel.
                                        If we're maintaining a weight we are happy with and our energy levels are good, then we know the burndown profile pattern is working for us.
                                        On the other hand, if we're gaining weight and/or feeling sluggish, then we can start to rethink our habits, make adjustments and track our progress by comparing our burndown profile over time.
                                    </p>
                                    <p>
                                        Why are there no numbers? Because numbers aren't always the most important thing. Here we're focused on looking for patterns and trends. You know what feels good to you. 
                                        Our goal is to provide visuals that can help you see what's going on, internalize it and assist you in making eating decisions that are right for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='modal-setting align-items-center'>
                            <button className="btn btn-link fudrik-text-light" onClick={openProfileModal}>Profile Settings</button>
                            <ProfileModal  show={isProfileModalOpen}  onClose={closeProfileModal}/>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    );

};

export default BurndownSpindleGraph;


