import React, { useState, useEffect } from 'react';

const MealSegmentsList = ({ meal_segments = {}, onUpdate }) => {
  const [mealSegments, setMealSegments] = useState(meal_segments);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMealSegments(meal_segments);
  }, [meal_segments]);

  const handleDelete = (foodItem) => {
    setIsLoading(true);

    setTimeout(() => {
      const updatedSegments = { ...mealSegments };
      delete updatedSegments[foodItem];
      setMealSegments(updatedSegments);
      onUpdate(updatedSegments);
      setIsLoading(false);
    }, 0); // Simulating a slight delay for the update

    // You can also handle error scenarios and handle setIsLoading(false) accordingly
  };

  return (
    <div>
      {Object.entries(mealSegments).map(([foodItem, caloricValue]) => (
        <div key={foodItem} className="ms-4 list-item-block fudrik-text-light">
          <div className="food-item">{foodItem}</div>
          <div className="calories">~{caloricValue}</div>
          <button className='ms-3 btn btn-link btn-sm' onClick={() => handleDelete(foodItem)} disabled={isLoading}>
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};

export default MealSegmentsList;


