import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'react-bootstrap'

function CognitionTimeoutModal ( { isOpen, restart, cancel } ) {

  return (
    <Modal 
        show={isOpen} 
        className="cognition-timeout-modal"
    >

        <ModalHeader>Cognition Timeout</ModalHeader>
      
        <ModalBody>
            <p>
            It's taking you a while to respond... Please click the button below to restart this section of the test or cancel the whole test to try again later.
            </p>
        </ModalBody>
        
        <ModalFooter>
            <div className='container-fluid'>
                <div className="d-flex justify-content-center">
                    <Button color="primary" onClick={restart}>Restart</Button>
                </div>
                <div className="d-flex justify-content-center">
                    <Button color="primary" onClick={cancel}>Cancel</Button>
                </div>
            </div>
        </ModalFooter>

    </Modal>
  )
};

export default CognitionTimeoutModal;


