import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import p5 from 'p5';
import AnimatedText from '../components/AnimatedText';

const Sketch = ( {isHaikuOpen} ) => {
    const canvasRef = useRef();

    useEffect(() => {
        const sketch = new p5(p => {
        let leaves = [];

        p.setup = () => {
            p.createCanvas(p.windowWidth, p.windowHeight);
            for(let i = 0; i < 100; i++) {
                leaves.push(new Leaf(p));
            }
        };

        p.draw = () => {
            p.background(42, 26, 64);
            for(let leaf of leaves) {
                leaf.update();
                  leaf.display();
                }
            };

            p.windowResized = () => {
               p.resizeCanvas(p.windowWidth, p.windowHeight);
            };

            class Leaf {
                constructor(p) {
                    const inertia = Math.random() * 0.5 + 0.9;
                    this.p = p;
                    this.pos = p.createVector(p.random(p.width), p.random(p.height));
                    this.vel = p.createVector(p.random(-inertia, inertia), p.random(1, 2));
                }

                update() {
                  this.pos.add(this.vel);
                  if(this.pos.y > this.p.height) {
                      this.pos.y = 0;
                  }
                  if(this.pos.x > this.p.width) {
                      this.pos.x = 0;
                  }
                }

                display() {
                    this.p.fill(129, 57, 119);
                    this.p.ellipse(this.pos.x, this.pos.y, 8, 8);
                }
            }
        }, canvasRef.current);

        return () => {
            sketch.remove();
        };

    }, [isHaikuOpen]);

    console.log('***** falling leaves animation loading now...')
    return (
        <div ref={canvasRef} />
    );
};




//======================================================
//======================================================
function HaikuModal({ isHaikuOpen, haiku, onClose }) {
    const haikuLines = haiku.split('\n');


    return (
        <Modal
            show={isHaikuOpen}
            onHide={onClose}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            backdropClassName='modal-backdrop'
            >
            <Modal.Body className="haiku-modal-body">
                <Sketch isHaikuOpen={isHaikuOpen} />
                <div className="centered-div outer-glow-light">
                <p className="haiku-title">a Haiku... for you.</p>
                <div className="haiku-text fudrik-text-light">
                    {haikuLines.map((line, index) => (
                    <div className="haiku-text-size" key={index}>{line}</div>
                    ))}
                </div>
                <div className='light haiku-footer-text'>
                    <AnimatedText newMessage="Please be patient while we finish logging your meal..." />
                </div>
                </div>
            </Modal.Body>
                <button onClick={onClose} className="haiku-close-btn btn-link btn">close</button>
        </Modal>

    
    );
}


export default HaikuModal;
// 