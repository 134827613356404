import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'react-bootstrap'

function CognitionPopupModal ( { isOpen, begin, cancel } ) {

  return (
    <Modal 
        show={isOpen} 
        className="cognition-popup-modal"
    >

        <ModalHeader>Cognition Test</ModalHeader>
      
        <ModalBody>
            <p>
            Make sure the room is quiet and ask for silence during the test, which should only take about 5 minutes.
            </p>
            <p>
            Click READY when you are ready to begin.
            </p>
        </ModalBody>
        
        <ModalFooter>
            <div className='container-fluid'>
                <div className="d-flex justify-content-center">
                    <Button color="primary" onClick={begin}>READY</Button>
                </div>
                <div className="d-flex justify-content-center">
                    <Button color="primary" onClick={cancel}>Cancel</Button>
                </div>
            </div>
        </ModalFooter>

    </Modal>
  )
};

export default CognitionPopupModal;


