import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { getCookie } from '../utils/cookie';
import { loggit } from '../utils/helpers';

import { useSettings } from '../settings/SettingsContext';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const LoggingHistory = ( { isOpen, onClose } ) => {
    const { isMobile } = useSettings();

    const [loggedMeals, setLoggedMeals] = useState([]);
    const [mealsLoaded, setMealsLoaded] = useState(false);
    const [selectedMeal, setSelectedMeal] = useState(null);
    const [showMealDateModal, setShowMealDateModal] = useState(null)

    useEffect(( isOpen ) => {
        const jwtToken = getCookie('jwtToken');
        try {
            const fetchLoggedMeals = async () => {
                const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}get/all`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                loggit('loggedMeals retrieved: ', data);
                setLoggedMeals(data);
                setMealsLoaded(true);
            };
            // Fetch logged meals from your API and set the state
            fetchLoggedMeals();
        } catch(error) {
            console.log('Error loading meals:', error);
        }
    }, [isOpen]);

    const handleDeleteMeal = async (id) => {
        // Delete meal from your API and update the state
        if (window.confirm("Are you sure you want to delete this meal? You can't undo it.")) {
            try {
                const jwtToken = getCookie('jwtToken');
                const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                loggit('Deletion response data: ', data);
                setLoggedMeals(loggedMeals.filter((meal) => meal._id !== id));
            } catch (error) {
                console.error('Error deleting the meal:', error);
            }
        }
    };
    
    const handleEditMeal = async (id, new_date_created) => {
        // update the date_created of a meal via the api at /update/date/:id
        const new_date_created_UTC = new Date(new_date_created.getTime() - new_date_created.getTimezoneOffset() * 60000); // adjusting for calendar picker timezone shenanigans
        loggit(JSON.stringify({ date_created_UTC: new_date_created_UTC.toISOString() }));
        try {
            const jwtToken = getCookie('jwtToken');
            loggit(`Attempting to make a request to API ${process.env.REACT_APP_FUDRIK_API_URI} for meal ID: ${id} with new date ${new_date_created_UTC}`)
            const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}update/date/${id}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ date_created: new_date_created_UTC.toISOString() }),
            });
            const data = await response.json();
            loggit('Edit response data: ', data);
            // success. update the state
            setLoggedMeals(loggedMeals.map((meal) => {
                    if (meal._id === id) {
                        return { ...meal, date_created: new_date_created };
                    } else {
                        return meal;
                    }
                }
            ));
        } catch (error) {
            console.error('Error editing the meal:', error);
        }
    };
    
    const sortedMeals = React.useMemo(() => {
        if (mealsLoaded) {
            const sortConfig = { key: 'date_created', direction: 'desc' };
            let sortedMeals = [...loggedMeals];
            sortedMeals.sort((a, b) => {
                const dateA = new Date(a[sortConfig.key]);
                const dateB = new Date(b[sortConfig.key]);
                if (dateA < dateB) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (dateA > dateB) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
            return sortedMeals;
        }
    }, [loggedMeals, mealsLoaded]);
    

    
    const handleCloseMealDateModal = () => {
        setShowMealDateModal(false);
    };

    const handleOpenMealDateModal = (meal) => {
        loggit('Opening meal date modal for meal: ', meal);
        setSelectedMeal(meal);
        setShowMealDateModal(true);
    };

    let totalCalories = 0;

    return (
            <Modal 
                show={isOpen} 
                onHide={onClose}
                size="xl"
                fullscreen="md-down"
                className="top-aligned-modal blurred-modal">

                <Modal.Header closeButton>
                    <div className='fudrik-tagline' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Modal.Title>Füdrik History</Modal.Title>
                    </div>
                </Modal.Header>
                <Table bordered hover className='fudrik-table'>
                <thead>
                    <tr>
                        <th>
                            Date Created
                        </th>
                        {!isMobile && (<>
                            <th>
                                Meal
                            </th> 
                        </> )}
                        <th>
                            Meal Summary
                        </th>
                        <th>
                            kcal
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>

                {mealsLoaded && (
                    <>
                        <tbody>
                            {sortedMeals.map((meal, index) => {
                                const dateToday = new Date(meal.date_created + 'Z');
                                const localDateTodayStr = dateToday.toLocaleDateString();
                                const localTimeTodayStr = dateToday.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                            
                                const localDateToday = new Date(localDateTodayStr);
                                const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][(localDateToday.getDay()+1)%7];
                                                    
                                // Create a special row for the new day
                                let newDayRow = null;

                                if (index === 0 || localDateTodayStr !== new Date(sortedMeals[index - 1].date_created + 'Z').toLocaleDateString() ) {
                                    newDayRow = (
                                        <tr key={meal._id + "-day"} className='new-day-container'>
                                            <td colSpan="5" className='new-day-row'>
                                                <div className="dotw">{dayOfWeek} &#8679;</div>
                                                <div className="estimated-calories">&#8679; Estimated kcal intake: {totalCalories}</div>
                                            </td>
                                        </tr>
                                    );                        
                                    totalCalories = 0;
                                }
                            
                                totalCalories += meal.calories;
                
                                return (
                                    <React.Fragment key = {meal._id}>
                                        {newDayRow}
                                        <tr>
                                            <td className='meal-columns'>
                                                <button className='meal-columns btn meal-date-button' onClick={() => handleOpenMealDateModal(meal)}>
                                                    {isMobile ? (
                                                        <>{localTimeTodayStr}</>
                                                    ) : (
                                                        <>{localDateTodayStr} {localTimeTodayStr}</>
                                                    )}
                                                    </button>
                                            </td>
                                            {!isMobile && (<><td>{meal.meal_type}</td></>)}
                                            <td className='meal-columns meal-summary-column'>{meal.meal_summary}</td>
                                            <td className='meal-columns calories-column'>~{meal.calories}</td>
                                            <td className='meal-columns'>
                                                <Button variant="danger" size="sm" onClick={() => handleDeleteMeal(meal._id)}>
                                                Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </>
                )}
                </Table>
                <Modal
                    size="md"
                    show={showMealDateModal} 
                    onHide={handleCloseMealDateModal}
                    className="blurred-modal"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>Changing date for:</h3> 
                            "{selectedMeal ? selectedMeal.meal_summary : ''}"
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className='text-center'>
                                <DatePicker 
                                    showTimeSelect
                                    dateFormat="Pp"
                                    selected={selectedMeal ? new Date(selectedMeal.date_created + 'Z') : null} 
                                    onChange={date => {
                                        const utcDate = new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()));
                                        setSelectedMeal({...selectedMeal, date_created: utcDate});
                                    }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowMealDateModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => { handleEditMeal(selectedMeal._id, selectedMeal.date_created); setShowMealDateModal(false); }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Modal>    
    );
};

export default LoggingHistory;





  