import { useEffect, useState } from 'react';

//@@@@@@@@@@ TEXT ANIMATION CODE (for later) @@@@@@@@@@
const AnimatedText = ({ newMessage = '' }) => {
    const [displayMessage, setDisplayMessage] = useState(newMessage.slice(0, 1));
    
    useEffect(() => {
        let index = 0;
        let random_number = 1;
        let random_timer = 1;
        
        
        function updateMessage() {
            if (index < newMessage.length+1) {
                setDisplayMessage(newMessage.slice(0, index+1));
                random_number = Math.floor(Math.random() * 5) + 1;
                index += random_number;
                random_timer = random_number * 40
                setTimeout(updateMessage, random_timer);
            } else {
                setDisplayMessage(newMessage);
                index = 0;
                random_number = 1;
                random_timer = 1;
                setTimeout(updateMessage, 4000);
            }
        }
        // console.log('***** animated text loading now...', newMessage);
        updateMessage();
    }, [newMessage]);

    return ( 
        <span>
            {displayMessage} 
        </span>
    );
};


export default AnimatedText;


