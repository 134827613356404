import React, { useState, useEffect } from 'react';

const AccessibilityToggle = ( { settingKey, value, onToggle } ) => {
  const [checked, setChecked] = useState(value);
  
  useEffect(() => {
    setChecked(value);
  }, [value]);
 
  const handleToggle = (event) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    onToggle(settingKey, newValue);
  };

  return (
    <div className="d-flex align-items-center form-switch">
      <input 
        className="form-check-input" 
        type="checkbox" 
        id="accessibilitySwitch"
        checked={checked}
        onChange={handleToggle} />
      <label className="form-check-label ms-2" htmlFor="accessibilitySwitch">
        Accessibility
      </label>
    </div>
  );
};

export default AccessibilityToggle;


