import React from 'react';
import { saveFudrikSettings } from '../utils/helpers';
import { useSettings } from './SettingsContext';

import SpeechRecToggle from './SpeechRecToggle';
import AccessibilityToggle from './AccessibilityToggle';
import FudrikSpeaksToggle from './FudrikSpeaksToggle';



const SettingsToggles = () => {
    const { settings, updateSettings } = useSettings();

    const handleSettingsToggle = async (key, value) => {
        // Update the siteSettings state
        const updatedSettings = { ...settings, [key]: value };
        updateSettings(updatedSettings);
      
        // Save the updated settings to the API
        saveFudrikSettings(updatedSettings);
    };

    return (
        <div className='p-3'> 
            <div className='modal-settings'>
                <div className='modal-setting d-flex align-items-start fudrik-text-dark'>
                    <SpeechRecToggle  settingKey="speech_recognition" value={settings.speech_recognition} onToggle={handleSettingsToggle} />
                </div>
                <div className='modal-setting d-flex align-items-start fudrik-text-dark'>
                    <AccessibilityToggle settingKey="accessibility" value={settings.accessibility} onToggle={handleSettingsToggle} />
                </div>
                <div className='modal-setting d-flex align-items-start fudrik-text-dark'>
                    <FudrikSpeaksToggle  settingKey="fudrik_speaks" value={settings.fudrik_speaks} onToggle={handleSettingsToggle} />
                </div>
            </div>
        </div>
    )

};

export default SettingsToggles;