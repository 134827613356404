//@@@@@@@@@@ COGNITION TEST OVERVIEW @@@@@@@@@@@@@@@@@
// This test is being run vocally in order to more accurately test the user's response/processing times without the interference of a potential tremor
// The user will be run through a series of subtests as part of the overall cognition test.
// Each subtest will have a number of challenges, and each subtest will increase in complexity as the test progresses.
// The user will be prompted visually to respond vocally to each challenge.
// The user's vocal response will trigger the next challenge in each subtest, and the next subtest in the overall test until complete.
// The user's vocal response will be recorded and sent to the backend for processing, where we will determine the response time for each challenge.
//      as well as analyze the audio data features to determine the user's vocal control as well as a general measure of their cognitive state.
// We will store this data in the database for later analysis and comparison as userTests (storing overall test meta and results) and userSubtests (storing individual subtest results).

import React, { useState, useEffect, useRef} from "react";
import { Modal, Button } from 'react-bootstrap';

import { loggit, saveCognitionSubtest } from '../utils/helpers';
import CognitionTimeoutModal from './CognitionTimeoutModal';
import CognitionPopupModal from './CognitionPopupModal';
import VoiceVisualizerA from "../components/VoiceVisualizerA";
// import { useSettings } from '../settings/SettingsContext';



function CognitionTestModal ({ isOpen, onClose }) {
    // const [settings, isMobile] = useSettings();
    
    const [pageTitle, setPageTitle] = useState('');
    const [pageSubtitle, setPageSubtitle] = useState('');
    // eslint-disable-next-line
    const [pageFooterMessage, setPageFooterMessage] = useState('placeholder for audio visualizations');
    const [isTimeoutModalVisible, setIsTimeoutModalVisible] = useState(false);
    const [isPopupModalVisible, setIsPopupModalVisible] = useState(false);
    const [isVisualizerActive, setIsVisualizerActive] = useState(false);
    const [challengeProgress, setChallengeProgress] = useState(0);
    
    const numberOfSubtestChallenges = useRef(0);
    const challengeCountId = useRef(0);
    const subtestCountId = useRef(0);
    const isPrompted = useRef(false);
    
    useEffect(() => {
        if (isOpen) {
            setPageTitle('Cognition Test');
            setPageSubtitle('Landing Page');
            loggit('Opening Cognition Test Modal');
            updateBoxesInBulk(5, null, "Building Test Session...");
            
            // generate the subtest structure
            newTestSession();
        }
        // eslint-disable-next-line
    }, [isOpen]);
    

    // during the test we display progress as a div width across the bottom of the UI. 
    // we use this to update that progress bar as the challengeCount herzBinWidths
    useEffect(() => {
        const newProgressBarValue = challengeCountId.current / numberOfSubtestChallenges.current * 100;
        // loggit('   Challenge Progress updated...', newProgressBarValue);
        setChallengeProgress( newProgressBarValue );
        // eslint-disable-next-line
    }, [challengeCountId.current, numberOfSubtestChallenges.current]);
    
    
    //===========================================================================================================================
    //================================================= GRID BOX DISPLAY ========================================================

    //___________________________________________________________
    //@@@@@@@@@@ GRID BOX for 3x3 Layout @@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [backdropColor, setBackdropColor] = useState('lightgray');
    const defaultBoxColor = 'lightgray';
    const boxLayoutGroups = {
        'left': [1,4,7],
        'right': [3,6,9],
        'top': [1,2,3],
        'bottom': [7,8,9],
        'cross': [2,4,5,6,8],
        'checkers': [1,3,5,7,9],
        'ring': [1,2,3,4,6,7,8,9],
        'all': [1,2,3,4,5,6,7,8,9],
    }
    const initialBoxStates = [
        { id: 1, color: defaultBoxColor, text: ' ' },
        { id: 2, color: defaultBoxColor, text: ' ' },
        { id: 3, color: defaultBoxColor, text: ' ' },
        { id: 4, color: defaultBoxColor, text: ' ' },
        { id: 5, color: defaultBoxColor, text: ' ' },
        { id: 6, color: defaultBoxColor, text: ' ' },
        { id: 7, color: defaultBoxColor, text: ' ' },
        { id: 8, color: defaultBoxColor, text: ' ' },
        { id: 9, color: defaultBoxColor, text: ' ' }
    ];
    const defaultBoxStates = JSON.parse(JSON.stringify(initialBoxStates));
    const copyDefaultBoxStates = () => { return JSON.parse(JSON.stringify(defaultBoxStates));  };
    const [boxes, setBoxes] = useState(initialBoxStates);

    const updateBoxesDetailed = (newBoxes) => {
        // loggit('============ Updating boxes with new configuration: ', newBoxes);
        if (newBoxes.length > 1) {
            const updatedBoxes = boxes.map((box) => {
                // Find the corresponding new box configuration
                const newBoxConfig = newBoxes.find(newBox => newBox.id === box.id);
                if (newBoxConfig) {
                    // Update the box with new configuration
                    return { 
                        ...box, 
                        color: newBoxConfig.color ? newBoxConfig.color : box.color, 
                        text: newBoxConfig.text ? newBoxConfig.text : box.text 
                    };
                }
                return box; // Return the box unchanged if no new configuration is found
            });
            
            setBoxes(updatedBoxes); // Update the state with the new boxes array
        }
        //oooooooo Example Usage ooooooooooooooo
        // copy defaultBoxStates into a new variable newBoxConfiguration... edit desired boxes... then:
        // updateBoxesDetailed(newBoxConfiguration)
    };    

    // Function to update the color and/or text of a box by id (1-9 for a 3x3 grid layout)
    const updateBoxesInBulk = (id = [1,2,3,4,5,6,7,8,9], color = defaultBoxColor, text = '') => {
        // Ensure id is always an array
        const ids = Array.isArray(id) ? id : [id];
        const newConfiguration = copyDefaultBoxStates(); // start with a fresh default copy
        for (let i = 0; i < ids.length; i++) {
            newConfiguration[ids[i] - 1]['color'] = color;
            newConfiguration[ids[i] - 1]['text'] = text;
        }
        updateBoxesDetailed(newConfiguration);
    };

    const resetAllBoxes = () => {
        setBackdropColor(defaultBoxColor);
        const newBoxConfiguration = copyDefaultBoxStates();
        updateBoxesDetailed(newBoxConfiguration);
    };
    //---------------------------------------------------
    //-------------  END GRID BOX DISPLAY ---------------
    //---------------------------------------------------
  


    //===========================================================================================================================
    //=================================================== VOICE HANDLING ========================================================

    //___________________________________________________________
    //@@@@@@@@@@ VOICE HANDLING @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // create a SpeechRecognition object that will listen for the user's vocal responses
    const [mediaStream, setMediaStream] = useState(null);
    const mediaRecorder = useRef(null);
    const recognition = useRef(null);
    const isAudioReadytoSend = useRef(false);
    let localAudioChunks = [];

    
    


    const spinupSpeechRecognition = async () => {
        loggit("Spinning up speech recognition");

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const newRecognition = new SpeechRecognition();
        newRecognition.continuous = true; // try continuous to false to detect single phrases at a time
        newRecognition.interimResults = true; // We want real-time results
        newRecognition.lang = 'en-US'; // Language
        newRecognition.maxAlternatives = 1; // We want only one alternative
        
        newRecognition.start();

        newRecognition.onspeechstart = () => {
            loggit("... You're speaking");
        };
        
        newRecognition.onspeechend = () => {
            loggit("... You're not speaking");
        };
        
        newRecognition.onresult = (event) => {
            const lastResult = event.results[event.resultIndex];
            if (isPrompted.current === true) {
                if (lastResult.isFinal) {
                    // loggit('... Result recieved - Next Challenge triggered by voice response');
                    // The user has finished speaking a phrase
                    const transcript = lastResult[0].transcript.trim();
                    console.log('Final transcript: ', transcript);
                    // set the subtest response_actual
                    testSession.current[subtestCountId.current].subtest_challenges[challengeCountId.current].response_actual = transcript;
                    // now trigger the next call based on this transcript
                    nextChallenge();
                }
            } else {
                // The user has not been prompted yet, so ignore the result and do nothing
                loggit('... Result recieved - Doing nothing because user has not yet been prompted');
            }
        };
        
        newRecognition.onend = () => {
            console.log('-- newRecognition.onend --')
            handleNoResponse();
        };
        
        newRecognition.onerror = (event) => {
            loggit('... Recognition error: ', event.error);
            if (event.error === 'aborted') { // Speech Recognition in use in another tab!!! shut it down...
                loggit('    Apologies... It appears another web page is already using speech recognition. Please disable it and try again. Or, you can manually enter your meal in the form provided.');
                shutdownSpeechRecognition();
            } else if (event.error === 'no-speech') {
                console.log('-- newRecognition --> event.error = no-speech --')
                handleNoResponse();
            }
        };

        recognition.current = newRecognition; // make it available to the rest of the component

        //@@@@@@@@@@ AUDIO FILE HANDLING and DATA VISUALIZATIONS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                // Create a new MediaRecorder instance, and attach the audio stream to it
                loggit('    spinupSpeechRecognition - setting up the Audio media stream and recorder');
                setMediaStream(stream);

                mediaRecorder.current = new MediaRecorder(stream, { mimeType: 'audio/webm; codecs=opus' });
                mediaRecorder.current.addEventListener('dataavailable', handleRecordingDataAvailable);
                mediaRecorder.current.addEventListener('stop', handleRecordingStop);
                // Start recording
                mediaRecorder.current.start();
                setIsVisualizerActive(true);
            })
            .catch(error => {
                loggit('Error getting user media: ', error);
            });
    };

    const handleRecordingDataAvailable = async (event) => {
        // loggit('... Audio recording - data available: ' + event.data.size );
        if (event.data.size > 0) {
            // add the audio data to the localAudioChunks array which we eventually convert to the audioBlob and send to the backend
            localAudioChunks.push(event.data);
        }
    };


    const handleRecordingStop = () => {
        loggit('... Audio recording - stopped');
        if (isAudioReadytoSend) {
            subtestSave(); // save the subtest results to the database
        }
    };

    const shutdownSpeechRecognition = () => {
        challengeCountId.current = 0;
        // if speech recognition is running, stop it and set to null
        if (recognition.current) {
            loggit("    Shutting down speech recognition.");
            recognition.current.onend = () => { };
            recognition.current.stop();
            recognition.current = null;
        }
        // if media stream is running, stop it and set to null
        if (mediaStream) {
            loggit("    Shutting down media stream.");
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
        // if media recorder is running, stop it and set to null
        if (mediaRecorder.current) {
            loggit("    Shutting down media recorder.");
            mediaRecorder.current.stop();
            mediaRecorder.current = null;
        }
        setIsVisualizerActive(false);
    };
    
    //@@@@@@@@@@ TIMEOUT HANDLING (if no response is detected within a certain time, shut down speech recognition)
    const handleNoResponse = () => {
        loggit('... No response detected');
        setIsTimeoutModalVisible(true);
    };

    //@@@@@@@@@@ FALSE START HANDLING @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   
    const handleFalseStart = () => {
        loggit('... False Start detected');
        
    };
    //---------------------------------------------------
    //---------------- END VOICE HANDLING ---------------
    //---------------------------------------------------



    //===========================================================================================================================
    //============================================== TEST SESSION CREATION ======================================================

    //___________________________________________________________
    //@@@@@@@@@@ GENERATE A NEW TEST SESSION @@@@@@@@@@@@@@@@@@@@
    
    const testId = useRef(null); 
    const testStartTime = useRef(null);
    const testEndTime = useRef(null);
    const testSession = useRef([]);
    const defaultSubtestLength = 8; // default number of challenges per subtest

    // generate a random UUID to use as testId sent to python backend
    const getNewTestId = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // generate a random UUID to use as testId sent to python backend
    };

    const getPromptsList = (subtestList) => {
        // get the list of prompts for the subtest type
        switch (subtestList) {
            case 'monosyllabicList-V1':// 100 words with varied Initial letters... both hard and soft
                return [
                    'Ducks', 'Cats', 'Pants', 'Desk', 'Keys', 'Bags', 'Boats', 'Pens', 'Beds', 'Dazed', 
                    'Bikes', 'Kites', 'Doors', 'Bells', 'Beans', 'Cups', 'Pots', 'Tents', 'Gates', 'Pins', 
                    'Pipes', 'Banks', 'Tanks', 'Caps', 'Bats', 'Jacks', 'Kits', 'Pots', 'Tents', 'Tabs',
                    'Vest', 'Zips', 'Bark', 'Docks', 'Golf', 'Dogs', 'Chairs', 'Turks', 'Burns', 'Bills',
                    'Books', 'Cakes', 'Cooks', 'Canes', 'Gains', 'Chains', 'Kicks', 'Pips', 'Pops', 'Pills',
                    'Fairs', 'Fans', 'Farms', 'Fasts', 'Fats', 'Fees', 'Figs', 'Fills', 'Firms', 'Apes',
                    'Sacks', 'Cells', 'Sands', 'Saves', 'Saws', 'Seals', 'Seats', 'Seeds', 'Sells', 'Sends',
                    'Vests', 'Vines', 'Votes', 'Wages', 'Wakes', 'Walls', 'Wants', 'Wards', 'Wares', 'Waves',
                    'Eggs', 'Elms', 'Eyes', 'Falls', 'Fails', 'Vile', 'Vex', 'Vow', 'Vats', 'Vet',
                    'Hats', 'Hens', 'Hips', 'Hogs', 'Homes', 'Hops', 'Horns', 'Hugs', 'Huts', 'Veil'
                ]
            case 'duosyllabicList-V1':// 100 Double Consonant words
                return [
                    'Bubble', 'Cookies', 'Daddy', 'Kitty', 'Puppy', 'Tacky', 'Piggy', 'Dapper', 'Chokers','Giggle',
                    'Tackle', 'Checkers', 'Ditto', 'Puppets', 'Goggle', 'Tattle', 'Bankers', 'Babble', 'Crackers', 'Bitter',
                    'Chatter', 'Titter', 'Kettles', 'Pickle', 'Digger', 'Picky', 'Buddies', 'Cactus', 'Dipper', 'Gable',
                    'Chuckle', 'Doodle', 'Tickers', 'Poodles', 'Peppers', 'Tinker', 'Cackle', 'Bumpers', 'Chipper','Picker', 
                    'Daggers', 'Bagle', 'Tickle', 'Bottle', 'Buckle', 'Purpose', 'Poppies', 'Table', 'Kicker', 'Dabble',
                    'Fickle', 'Fable', 'Fader', 'Fiddle', 'Fatter', 'Faker', 'Faded', 'Vapor', 'Viper', 'Vocal',
                    'Vigor', 'Vital', 'Vivid', 'Vibrant', 'Vittles', 'Vicar', 'Water', 'Waddle', 'Waffle', 'Wiggle', 
                    'Wobble', 'Waiter', 'Waded', 'Woken', 'Wicker', 'Wicked', 'Saddle', 'Seesaw', 'Sassy', 'Soccer',
                    'Sucker', 'Saber', 'Sable', 'Savor', 'Sickle', 'Sizzle', 'Supper', 'Super', 'Suffer', 'Souffle',
                    'Yodel', 'Yogurt', 'Yanky', 'Yonder', 'Hater', 'Hackles', 'Hopper', 'Hacker', 'Hippo', 'Haggle'
                ]
            case 'multisyllabicList-V1':// 100 Complex words focusing on the harder consonants (generally)
                return [
                    'Inevitable', 'Specific', 'Family', 'Hospital', 'Document', 'Vegetable', 
                    'Chocolate', 'Interesting', 'Favorite', 'Important', 'Suddenly', 'Temperature', 
                    'Different', 'Comfortable', 'Natural', 'Actually', 'Personal', 'Business', 
                    'Calendar', 'Exercise', 'Language', 'Probably', 'Remember', 'Together', 
                    'Children', 'Eventually', 'Questionable', 'Apparently', 'Internet', 'Tomorrow', 'Beautiful', 
                    'Particular', 'Education', 'Government', 'Everything', 'Possible', 'Special', 
                    'Difficult', 'Material', 'Mechanical', 'Immediately', 'Situation', 'Conversation', 
                    'Environment', 'Development', 'University', 'Celebration', 'Professional', 
                    'Information', 'Direction', 'International', 'Electrical', 'Population', 
                    'Photography', 'Technology', 'Economy', 'Forgetful', 'Politics', 'Industry', 
                    'Medicine', 'Operation', 'Literature', 'Methodology', 'Geography', 'Electricity', 
                    'Biology', 'Mathematics', 'Transportation', 'Responsibility', 'Organization', 
                    'Communication', 'Administration', 'Revolution', 'Restaurant', 'Philosophy', 
                    'Psychology', 'Extremely', 'Analysis', 'Television', 'Community', 'Opportunity', 
                    'Understand', 'Experience', 'Expression', 'Paratrooper', 'Suggestion', 'Necessary', 
                    'Challenge', 'Responsible', 'Management', 'Talkative', 'Character', 'Chemical', 
                    'Individual', 'Manageable', 'Operative', 'Ability', 'Relationship', 
                    'Application', 'Recognition', 'Concentration',
                ]
            case 'leftRightList':
                return [ 'left', 'right', 'top', 'bottom' ]
            case 'colorsList':
                return [ '#C60000-red', '#0088EA-blue', '#f0D20D-yellow', 'purple-purple', '#1FD100-green', '#ff859a-pink' ]; // '#ff5500-orange', orange seems too similar to red .... can potentially play with this but for now, just pulling it.
            case 'boolLeftRightList': // <use this placement>-<show this text>-<expect this boolean>//let [display, prompt, response_expected] = str.split('-');
                return [ 
                    'left-left-true', 'left-right-false', 'left-left-true', 'left-top-false', 'left-left-true', 'left-bottom-false',
                    'right-right-true',  'right-left-false', 'right-right-true',  'right-top-false', 'right-right-true',  'right-bottom-false', 
                    'top-top-true', 'top-bottom-false','top-top-true', 'top-left-false','top-top-true', 'top-right-false', 
                    'bottom-bottom-true', 'bottom-top-false','bottom-bottom-true','bottom-left-false','bottom-bottom-true','bottom-right-false',
                ]
            case 'boolColorsList':// <show this color>-<show this text>-<expect this boolean> //let [display, prompt, response_expected] = str.split('-');
                return [ 
                    '#C60000-red-true', '#C60000-red-true', '#C60000-blue-false', '#C60000-red-true', '#C60000-yellow-false', '#C60000-red-true', '#C60000-purple-false', '#C60000-red-true', '#C60000-orange-false', '#C60000-red-true', '#C60000-green-false', '#C60000-red-true', '#C60000-pink-false','#C60000-red-true', 
                    '#0088EA-blue-true', '#0088EA-red-false', '#0088EA-blue-true', '#0088EA-yellow-false', '#0088EA-blue-true', '#0088EA-purple-false','#0088EA-blue-true', '#0088EA-blue-true',  '#0088EA-orange-false', '#0088EA-blue-true', '#0088EA-green-false', '#0088EA-pink-false','#0088EA-blue-true', 
                    '#f0D20D-yellow-true', '#f0D20D-yellow-true', '#f0D20D-red-false', '#f0D20D-blue-false', '#f0D20D-yellow-true', '#f0D20D-purple-false','#f0D20D-yellow-true', '#f0D20D-yellow-true', '#f0D20D-orange-false', '#f0D20D-green-false','#f0D20D-yellow-true',  '#f0D20D-pink-false','#f0D20D-yellow-true', 
                    'purple-purple-true', 'purple-red-false', 'purple-purple-true', 'purple-blue-false', 'purple-purple-true', 'purple-yellow-false', 'purple-purple-true', 'purple-orange-false', 'purple-purple-true', 'purple-green-false', 'purple-purple-true', 'purple-pink-false',
                    // '#ff5500-orange-true', '#ff5500-red-false', '#ff5500-orange-true', '#ff5500-blue-false', '#ff5500-orange-true', '#ff5500-yellow-false', '#ff5500-purple-false', '#ff5500-orange-true', '#ff5500-green-false', '#ff5500-orange-true', '#ff5500-pink-false','#ff5500-orange-true', 
                    '#1FD100-green-true', '#1FD100-red-false', '#1FD100-green-true', '#1FD100-blue-false', '#1FD100-green-true', '#1FD100-yellow-false', '#1FD100-green-true', '#1FD100-purple-false', '#1FD100-green-true', '#1FD100-orange-false', '#1FD100-green-true', '#1FD100-pink-false','#1FD100-green-true', 
                    '#ff859a-pink-true','#ff859a-red-false', '#ff859a-pink-true','#ff859a-blue-false', '#ff859a-pink-true','#ff859a-yellow-false', '#ff859a-pink-true','#ff859a-purple-false', '#ff859a-pink-true','#ff859a-orange-false', '#ff859a-pink-true','#ff859a-green-false', '#ff859a-pink-true',
                ]
            default:
                loggit('    ERROR: Subtest Type not recognized');
                break;
        }
    };

    const newTestSession = () => {
        testId.current = getNewTestId();
        loggit('    New Test Session ID: ', testId.current);
        testSession.current = [
            {
                subtest_id: 0,
                subtest_type: 'monosyllabic',
                subtest_name: 'Monosyllabic Words',
                subtest_description: 'Testing for pure response time using simple monosyllabic words, measuring a baseline using the syllable that the user responds to the fastest (should typically be the harder consonants)',
                subtest_format: 'Center Only', // grid type
                subtest_list: 'monosyllabicList-V1', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
            {
                subtest_id: 1,
                subtest_type: 'duosyllabic',
                subtest_name: 'Two Syllable Words',
                subtest_description: 'Testing for pure response time and vocal control (lips, tongue, jaw, etc.)',
                subtest_format: 'Center Only', // grid type
                subtest_list: 'duosyllabicList-V1', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
            {
                subtest_id: 2,
                subtest_type: 'multisyllabic',
                subtest_name: 'Complex Words',
                subtest_description: 'Testing for response time to cold read (no preview) and vocal control (lips, tongue, jaw, etc.)',
                subtest_format: 'Center Only', // grid type
                subtest_list: 'multisyllabicList-V1', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
            {
                subtest_id: 3,
                subtest_type: 'leftright',
                subtest_name: 'Left|Right|Top|Bottom',
                subtest_description: 'Testing for response time and identification of configurations',
                subtest_format: 'Grid Configurations', // grid type
                subtest_list: 'leftRightList', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
            {
                subtest_id: 4,
                subtest_type: 'color',
                subtest_name: 'Colors',
                subtest_description: 'Testing for response time and identification of colors',
                subtest_format: 'Grid Colors', // grid type
                subtest_list: 'colorsList', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
            {
                subtest_id: 5,
                subtest_type: 'bool_leftright',
                subtest_name: 'Left|Right|Top|Bottom - True/False',
                subtest_description: 'Testing for response time of reasoning, logic and executive function',
                subtest_format: 'Grid Configurations', // grid type
                subtest_list: 'boolLeftRightList', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
            {
                subtest_id: 6,
                subtest_type: 'bool_color',
                subtest_name: 'Colors - True/False',
                subtest_description: 'Testing for response time of reasoning, logic and executive function',
                subtest_format: 'Grid Colors', // grid type
                subtest_list: 'boolColorsList', // which global list to use to generate the challenges
                subtest_length: defaultSubtestLength,
                subtest_start_time: null,
                subtest_end_time: null,
                subtest_challenges: []
            },
        ];
        
        
        // generate the subtest structure iterating through the testSession object
        for (let i = 0; i < testSession.current.length; i++) {
            generateNewSubtest(i);
        };

        loggit('NEW Test Session: ', testSession.current);
        
        // start the test
        testStartTime.current = Date.now();
        subtestCountId.current = 0;
        challengeCountId.current = 0;

        setIsPopupModalVisible(true);
    };    
    
    
    const generateNewSubtest = (subcount) => {
        // generate the subtest challenges based on subtest settings
        let challenges = [];
        const promptsList = getPromptsList(testSession.current[subcount].subtest_list);
        let lastPrompt = '';
        for (let i = 0; i < testSession.current[subcount].subtest_length; i++) {
            //  choose a random prompt from the subtest_list
            const randomIndex = Math.floor(Math.random() * promptsList.length);
            const randomPrompt = promptsList[randomIndex];
            // make sure the prompt is not the same as the last prompt to prevent duplicates
            if (randomPrompt === lastPrompt) {
                i -= 1; // decrement the counter to try again
                continue;
            }
            let prompt = randomPrompt;
            let promptConfig = null;
            let responseExpected = randomPrompt;
            switch (testSession.current[subcount].subtest_type) {
                case 'monosyllabic':
                case 'duosyllabic':
                case 'multisyllabic':
                    // make sure the prompt is not already in the challenges array to prevent duplicates
                    let isPromptAlreadyIncluded = challenges.some(challenge => challenge.prompt === randomPrompt);
                    if (isPromptAlreadyIncluded) {
                        i -= 1; // decrement the counter to try again
                        continue;
                    }
                    break;
                case 'leftright':
                    // Do nothing special
                    break;
                case 'color':
                    // format is color to display, then response expected
                    [promptConfig, responseExpected] = randomPrompt.split('-');
                    break;
                case 'bool_leftright':
                case 'bool_color':
                    // split the special prompt format into its parts to get the text-to-display and the response-expected which are not the same in these cases
                    [promptConfig, prompt, responseExpected] = randomPrompt.split('-');
                    break;
                default:
                    loggit('    ERROR: Subtest Type not recognized');
                    break;
            };
            lastPrompt = randomPrompt;

            const challenge = {
                prompt: prompt,
                prompt_config: promptConfig,
                prompt_begin_marker: null, //  empty till challenge is triggered
                prompt_end_marker: null, //  empty till challenge is triggered
                response_expected: responseExpected,
                response_actual: null, //  empty till evaluation
                accuracy: null, //  empty till evaluation
                // response times will be calculated in the backend. stubbing them out now for clarity.
                response_begin_marker: null, //  empty till processing
                response_time: null, //  response_begin_marker - promptMarker
                response_end_marker: null, // empty till processing
                response_duration: null, //  response_end_marker - response_begin_marker
                response_z_score: null, //  empty till processing - ( (response_time - mean(response_time)) / std(response_time) )
                response_wav_url: null, //  empty till processing
            };
            challenges.push(challenge);
        }

        // Finishing up by adding the challenges to the subtest object
        testSession.current[subcount].subtest_challenges = challenges;
        loggit('    Subtest - New Challenges generated: ', challenges);
    };
    //---------------------------------------------------
    //----------- END TEST SESSION CREATION -------------
    //---------------------------------------------------

    
                
    //===========================================================================================================================
    //=============================================== SUBTEST ACTIONS ===========================================================

    //___________________________________________________________
    //@@@@@@@@@@ SUBTEST BEGIN @@@@@@@@@@@@@@@@@@@@@@@@@
    const subtestBegin = () => {
        loggit('    Begin Subtest - Count: ', subtestCountId.current);
        // set the subtest start time within the testSession object
        testSession.current[subtestCountId.current].subtest_start_time = Date.now();
        if (isPopupModalVisible === true) { setIsPopupModalVisible(false) }
        
        // get speechRecognition ready to go
        spinupSpeechRecognition();

        // reset the loading bar
        numberOfSubtestChallenges.current = testSession.current[subtestCountId.current].subtest_length; 
        displayPrompt();
    };
    //---------------------------------------------------


    //___________________________________________________________
    //@@@@@@@@@@ SAVE SUBTEST RESULTS @@@@@@@@@@@@@@@@@@@@@@@@@@@
    const subtestSave = async () => {
        if (isAudioReadytoSend.current === true) {
            resetAllBoxes();
            // reset the isAudioReadytoSend variable now that the audio file is being sent to the backend
            isAudioReadytoSend.current = false;

            // set the subtest end time within the testSession object
            testSession.current[subtestCountId.current].subtest_end_time = Date.now();
            
            const audioBlob = new Blob(localAudioChunks, { 'type' : 'audio/webm; codecs=opus' });
            localAudioChunks = []; // Clear the recorded chunks

            let nextUp = null;
            if (subtestCountId.current < testSession.current.length - 1) {
                nextUp =  'Next up: ' + testSession.current[subtestCountId.current + 1].subtest_name ;
            } else {
                nextUp =  'End of Test Session';
            }
            const newConfiguration = [
                { id: 2, color: defaultBoxColor, text: 'Remember to breath...' },
                { id: 5, color: 'white', text: nextUp }
            ];
            updateBoxesDetailed(newConfiguration);
            
            const testSessionData = {
                test_id: testId.current,
                test_start_time: testStartTime.current,
                test_end_time: testEndTime.current,
            };
            const subtestData = testSession.current[subtestCountId.current];

            //++++++++++++++++ save the test/subtest info to the database with audio file ++++++++++++++++
            const apiResult = await saveCognitionSubtest(audioBlob, testSessionData, subtestData);
            // wait for a response from the backend before moving on to the next subtest
            if (apiResult) {
                // herzBinWidth the process counts
                subtestCountId.current += 1;
                challengeCountId.current = 0;
                if (subtestCountId.current < testSession.current.length) {
                    subtestBegin(); // start the next subtest
                } else {
                    loggit('End of Test Session');
                    testSessionClose();
                }
            } else {
                // Handle failure notification
                loggit('Problem Saving to Backend - End of Test Session');
                testSessionClose();
            }
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // // herzBinWidth the process counts
            // subtestCountId.current += 1;
            // challengeCountId.current = 0;
            // if (subtestCountId.current < testSession.current.length) {
            //     spinupSpeechRecognition(); // restart the speech recognition and media recorder
            //     subtestBegin(); // start the next subtest
            // } else {
            //     loggit('End of Test Session');
            //     testSessionClose();
            // }

        }

    };
    //---------------------------------------------------
    

    //___________________________________________________________
    //@@@@@@@@@@ SUBTEST RESET @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const subtestReset = () => {
        loggit('    Resetting Subtest');
        challengeCountId.current = 0;
        setBoxes(initialBoxStates);
        
        setIsTimeoutModalVisible(false);
        
        // regenerate the subtest structure and start the subtest again
        generateNewSubtest(subtestCountId.current);
        spinupSpeechRecognition();
        subtestBegin();
    };
    //---------------------------------------------------
    //--------------- END SUBTEST ACTIONS --------------
    //---------------------------------------------------
    



    //===========================================================================================================================
    //=============================================== SUBTEST CHALLENGE CONTROLS ================================================

    //___________________________________________________________
    //@@@@@@@@@@ NEXT CHALLENGE (Triggered by vocal response) @@@
    const nextChallenge = () => {
        isPrompted.current = false;
        // set the prompt end marker
        testSession.current[subtestCountId.current].subtest_challenges[challengeCountId.current].prompt_end_marker = Date.now() - testSession.current[subtestCountId.current].subtest_start_time;
      
        // herzBinWidth the challenge count
        challengeCountId.current += 1;       
        if (challengeCountId.current < numberOfSubtestChallenges.current) {
            loggit ('    ** Next Challenge - Count: ', challengeCountId.current);
            displayPrompt();
        } else {
            loggit('     ** End of challenges, moving on...');
            // set the variable that will trigger sending the subtest results to the database (only after the mediaRecorder has stopped)
            isAudioReadytoSend.current = true;
            // media recorder on stop will trigger the subtestSave function if isAudioReadytoSend is true
            shutdownSpeechRecognition();
        }
    };
    //---------------------------------------------------

    //___________________________________________________________
    //@@@@@@@@@@ DISPLAY CHALLENGE PROMPT @@@@@@@@@@@@@@@@@@@@@@@
    const displayPrompt = () => {
        resetAllBoxes();

        // update the display content
        const nextPrompt = testSession.current[subtestCountId.current].subtest_challenges[challengeCountId.current].prompt;
        loggit('    Displaying prompt for', testSession.current[subtestCountId.current].subtest_type, 'subtest:  "', nextPrompt, '"');
        switch (testSession.current[subtestCountId.current].subtest_type) {
            case 'monosyllabic':
            case 'duosyllabic':
                // we show the prompt to the user before we turn it green to trigger response
                updateBoxesInBulk(5, "lightgray", nextPrompt.toUpperCase());
                break;
            case 'color':
                updateBoxesInBulk(5, defaultBoxColor, 'Name the Color...');
                break;
            case 'bool_leftright':
            case 'bool_color':
                // we show a prompt to the user to remind them to give a true or false response
                // updateBoxesDetailed({ id: 5, color: defaultBoxColor, text: 'True or False?' })
                updateBoxesInBulk(5, defaultBoxColor, 'True or False?');
                break;
            default:
                // updateBoxesInBulk( null, "lightgray", "");
                break;
        }

        // create a random pause between 1.5 and 3 seconds
        const randomPause = Math.floor(Math.random() * 1500) + 1500;
        let nextPromptConfig = null;
        setTimeout(() => {
            isPrompted.current = true;
            switch (testSession.current[subtestCountId.current].subtest_type) {
                case 'monosyllabic':
                case 'duosyllabic':
                case 'multisyllabic':
                    
                    const newBoxConfiguration = copyDefaultBoxStates(); // start with a fresh default copy
                    let ids = null;
                    // update a box for every box in the boxLayoutGroup selected with the nextPromptConfig which in this case is left right top or bottom
                    ids = boxLayoutGroups['ring'];
                    for (let i = 0; i < ids.length; i++) {
                        newBoxConfiguration[ids[i] - 1]['color'] = 'green';
                    };
                    // now set the middle box's text to the nextPrompt
                    newBoxConfiguration[5-1]['text'] = nextPrompt.toUpperCase(); // 5-1, where 5 is the middle box, because the box ids are 1-9 but the array is 0-8
                    updateBoxesDetailed(newBoxConfiguration);
                    setBackdropColor('green');
                    break;
                case 'leftright':
                    const grouping = boxLayoutGroups[nextPrompt];
                    updateBoxesInBulk(grouping, 'orange', '');
                    break;
                case 'color':
                    nextPromptConfig = testSession.current[subtestCountId.current].subtest_challenges[challengeCountId.current].prompt_config;
                    updateBoxesInBulk(boxLayoutGroups['ring'], nextPromptConfig, '');
                    setBackdropColor(nextPromptConfig);
                    break;
                case 'bool_leftright':
                case 'bool_color':
                    nextPromptConfig = testSession.current[subtestCountId.current].subtest_challenges[challengeCountId.current].prompt_config;
                    displayBooleanPrompt(nextPrompt, nextPromptConfig);
                    setBackdropColor(nextPromptConfig);
                    break;
                default:
                    loggit('    ERROR: Subtest Type not recognized');
                    break;
            }
            // set the prompt begin marker
            testSession.current[subtestCountId.current].subtest_challenges[challengeCountId.current].prompt_begin_marker = Date.now() - testSession.current[subtestCountId.current].subtest_start_time;
        }, randomPause);

    };
    //---------------------------------------------------

    const displayBooleanPrompt = (nextPrompt, nextPromptConfig) => {
        const newBoxConfiguration = copyDefaultBoxStates(); // start with a fresh default copy
        let ids = null;
        switch (testSession.current[subtestCountId.current].subtest_type) {
            case 'bool_leftright':
                // update a box for every box in the boxLayoutGroup selected with the nextPromptConfig which in this case is left right top or bottom
                ids = boxLayoutGroups[nextPromptConfig];
                for (let i = 0; i < ids.length; i++) {
                    newBoxConfiguration[ids[i] - 1]['color'] = 'orange';
                };
                // now set the middle box's text to the nextPrompt
                newBoxConfiguration[5-1]['text'] = nextPrompt.toUpperCase(); // 5-1, where 5 is the middle box, because the box ids are 1-9 but the array is 0-8
                updateBoxesDetailed(newBoxConfiguration);
                break;
            case 'bool_color': 
                ids = boxLayoutGroups['ring'];
                for (let i = 0; i < ids.length; i++) {
                    newBoxConfiguration[ids[i] - 1]['color'] = nextPromptConfig;
                };
                // now set the middle box's text to the nextPrompt
                newBoxConfiguration[5-1]['color'] = 'white'; // 5-1, where 5 is the middle box, because the box ids are 1-9 but the array is 0-8
                newBoxConfiguration[5-1]['text'] = nextPrompt.toUpperCase(); // 5-1, where 5 is the middle box, because the box ids are 1-9 but the array is 0-8
                updateBoxesDetailed(newBoxConfiguration);
                break;
            default:
                loggit('    ERROR: displayBooleanPrompt() called in error... Subtest Type not recognized');
                break;
        }
    };
    //---------------------------------------------------
    //--------- END SUBTEST CHALLENGE CONTROLS ----------
    //---------------------------------------------------



    
    //===========================================================================================================================
    //=============================================== CLOSING ACTIONS ===========================================================
    
    //___________________________________________________________
    //@@@@@@@@@@ TEST SESSION CANCEL @@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const testSessionClose = () => {
        // Eventually we will want to insert a modal here to close out the user experience. for now we will just close the modal
        closeThisModal();
    };
    //---------------------------------------------------
    
    
    
    const closeThisModal = () => {
        // reset the test session
        testSession.current = [];

        loggit('Closing Cognition Test Modal');
        resetAllBoxes();

        // shut down the speech recognition and media recorder
        shutdownSpeechRecognition();

        onClose();
    };

    //---------------------------------------------------
    //--------------- END CLOSING ACTIONS ---------------
    //---------------------------------------------------
    

    if (!isOpen) { return null; }
    return (
      <Modal 
        show={isOpen}
        fullscreen={true}
        className="cognition-test-modal"
      >

        <Modal.Header>
            <Modal.Title>{pageTitle}</Modal.Title>
            <div>{pageSubtitle}</div>
            <Button variant="secondary" className={`me-2 modal-cancel-button btn-sm`} onClick={closeThisModal} >Cancel</Button>
        </Modal.Header>

        <Modal.Body>
            <div className="container-fluid h-100" style={{backgroundColor: backdropColor}}>
                <div className="row h-100">
                    {boxes.map((box, index) => (
                        <div key={box.id}
                        className={`col-4 d-flex justify-content-center align-items-center cognition-box-text`}
                        style={{ backgroundColor: box.color ,
                        borderRadius: box.id === 5 ? '30px' : '0px'}}>
                        {box.text}
                    </div>
                    ))}
                </div>
                <div className="row no-gutters col-12 w-100" style={{ backgroundColor: '#666666' }}>
                    <div className="col-12 px-0">
                        <div className="progress-bar" 
                            style={{ width: `${challengeProgress}%`, backgroundColor: '#aaaaaa', height: '20px' }}>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>

        <Modal.Footer>
            <VoiceVisualizerA isOpen={isVisualizerActive} mediaStream={mediaStream} />
        </Modal.Footer>

        <CognitionTimeoutModal isOpen={isTimeoutModalVisible} restart={subtestReset} cancel={testSessionClose} />
        <CognitionPopupModal isOpen={isPopupModalVisible} begin={subtestBegin} cancel={testSessionClose} />

      </Modal>  
    );

};

export default CognitionTestModal;