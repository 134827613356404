import React, { useState } from 'react';
import { Card, Button, Collapse } from 'react-bootstrap';

import LogMeal from './LogMeal';

const WhatsappPendingMeals = ({ meal, removeMeal }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleDoneLoggingMeal = () => {
        removeMeal(meal.meal_prep_id);
        setIsVisible(false);
    };


    // Format the date
    const date = new Date(meal.date_created);
    const options = {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZoneName: 'short'
    };
    
    const formattedDate = date.toLocaleString('en-US', options);

    const [collapseOpen, setCollapseOpen] = useState(false);
    const handleOpenEditor = () => {
        setCollapseOpen(!collapseOpen);
    };

    return (
        isVisible &&
            <Card className="mb-4 card">
            <Card.Body>
                <Card.Title>Meal recorded on {formattedDate}</Card.Title>
                    {meal.message_prep[0] && (
                <h3>
                    <div className=''>{meal.message_prep[0].content}</div>
                </h3>
                )}
                <img className="img-fluid" src={meal.image_url} alt="Meal" />
                <Button className="mt-2 w-100" variant="primary" onClick={handleOpenEditor}>
                    Log this Meal
                </Button>
                <Collapse in={collapseOpen}>
                <div>
                    <LogMeal meal={meal} onHide={() => handleDoneLoggingMeal()}/>
                </div>
                </Collapse>

            </Card.Body>
            </Card>
    );
};

export default WhatsappPendingMeals;
