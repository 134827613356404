import React, { useState, useRef, useEffect } from 'react';
import { Modal, Container, Row, Button } from 'react-bootstrap';

import { loggit } from '../utils/helpers';
import { openaiTouchpointCheckin, openaiDeleteTouchpointCheckin } from '../utils/openai';
import { useSettings } from '../settings/SettingsContext';

import AnimatedText from '../components/AnimatedText';
import SpeechRecognition from '../components/SpeechRecognition';


const TouchpointCheckinModal = ({ isOpen, onClose }) => {
    const {settings, isSettingsLoading } = useSettings();
    const isThisModalOpen = useRef(false); // a useState isn't fast enough

    const [summaryText, setSummaryText] = useState("");

    const buttonSizeClass = settings.accessibility ? "btn-lg" : "btn-sm";

    //_____________________________________________________________________________________
    //@@@@@@@@@@ SPEECH RECOGNITION RELATED CODE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [conversationModeOverride, setConversationModeOverride] = useState(false); // used to override the conversation mode in the SpeechRecognition component
    //eslint-disable-next-line
    const [isLastStep, setIsLastStep] = useState(false);
    const [transcriptMessage, setTranscriptMessage] = useState("");
    //eslint-disable-next-line
    const [assistantMessage, setAssistantMessage] = useState("");
    const [spokenMessage, setSpokenMessage] = useState("");
    
    const [isProcessBarActivated, setIsProcessBarActivated] = useState(false);
    const [processBarMessage, setProcessBarMessage] = useState("");
    const [isListening, setIsListening] = useState(false);
    // callback for the SpeechRecognition component to toggle the isListening state
    const toggleIsListening = (bool) => {
        setIsProcessBarActivated(bool);
        setIsListening(bool);
        if (bool) {
            setProcessBarMessage("Go ahead. I'm Listening...");
        } else {
            setProcessBarMessage('');
        }
    };

    const [transcribedText, setTranscribedText] = useState(''); // used to hold the new transcribed text with which we summarize meal
    const newTranscribedText = useRef(''); // used to hold the new transcribed text with which we summarize meal
    useEffect(() => {
        loggit('     • Setting new transcribed text: ', transcribedText)
        newTranscribedText.current = transcribedText;
    }, [transcribedText]);
        
    //@@@@@@@@@@ FOCUS INTO THE TRANSCRIBED TEXT INPUT @@@@@@@@@
    var focusTextInput = useRef(false);
    //eslint-disable-next-line
    const focusTranscribedTextInput = () => {
        focusTextInput.current = true; // this will trigger the focusTextInput effect within the speechrecognition component
    };

    //@@@@@@@@@@ SPEECH RECOGNITION COMPONENT Activation Controllers @@@@@@@@@@@@@@@@@@@@@@
    const [isSpeechRecognitionEnabled, setIsSpeechRecognitionEnabled] = useState(false);
    useEffect(() => {
        if (settings.speech_recognition || settings.fudrik_speaks) {
            setIsSpeechRecognitionEnabled(true);
        } else {
            setIsSpeechRecognitionEnabled(false);
        }
    }, [settings.speech_recognition , settings.fudrik_speaks]);
    

    //@@@@@@@@@@ T-Minus SPEECH RECOGNITION @@@@@@@@@@
    //eslint-disable-next-line 
    const tminusSpeechRecognition = () => {  // we won't use this on this page
        // create 3 second countdown timer
        var tminusCountdown = 3;
        const countItDown = () => {
            if (tminusCountdown > 0) {
                // setSpokenMessage(tminusCountdown);
                setTranscriptMessage(`Active Listening begins in... ${tminusCountdown}`);
                tminusCountdown -= 1;
                setTimeout(() => {
                    countItDown();
                }, 1000);
            } else {
                tminusCountdown = 3;
                setTranscriptMessage("How are you feeling?");
                toggleIsListening(true);
            }
        };
        countItDown();
    };
    //-------------------------- END SPEECH RECOGNITION ------------------------------------------


    //_____________________________________________________________________________________
    //@@@@@@@@@@ TOUCHPOINT CHECKIN  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [isFetching, setIsFetching] = useState(false);
    const toggleApiCalling = (bool) => {
        if (bool) {
            loggit('     • TOUCHPOINT CHECKIN BEGINS - setting isListening to "false".');
            toggleIsListening(false);
            setIsFetching(true);
            setIsProcessBarActivated(true);
            setProcessBarMessage("Go ahead. I'm Listening...");
        } else {
            loggit('     • TOUCHPOINT CHECKIN ENDS.');
            setIsFetching(false);
            setIsProcessBarActivated(false);
            setProcessBarMessage("");
            setTranscribedText(''); // reset the transcribed text in this and child components
            if (settings.fudrik_speaks && !isLastStep) toggleIsListening(true);
        }
    };
    //---------------------- END toggleApiCalling ---------------------------------------


    //_____________________________________________________________________________________
    //@@@@@@@@@@ Touchpoint Checkin API Call @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleApiCall = async (transcription) => {
        if (isThisModalOpen.current && transcription) {
            toggleApiCalling(true); 
            
            setTranscriptMessage('Hang tight...');
            setAssistantMessage('Processing message: "' + newTranscribedText.current + '"');
            
            setTranscribedText(''); // reset it now that we've used it
            
            let response = await openaiTouchpointCheckin(transcription);

            checkinId.current = response.touchpoint_checkin_id;
            let groomed_response = `Your wellness rating is ${response.wellness_rating} out of 100.\n\n`;
            groomed_response += response.keywords.map(keyword => `• ${keyword}`).join('\n');

            toggleApiCalling(false);
            setAssistantMessage('');

            setIsLastStep(true);
            setSummaryText(groomed_response);
        } else {
            loggit('     • Not calling API because modal is closed or transcription is empty.');
        }

    };
    //-------------------------- END Sitrep API Call ------------------------------------------


    //_____________________________________________________________________________________
    //@@@@@@@@@@ DELETE THE CHECKIN @@@@@@@@
    const checkinId = useRef('');
    const  deleteTheCheckin = async (checkinId) => {
        if (checkinId) {
            let response = await openaiDeleteTouchpointCheckin(checkinId);
            if (response) {
                loggit('     • Successfully deleted checkinId: ', checkinId);
            } else {
                loggit('     • Failed to delete checkinId: ', checkinId);
            }
        }
    };
    //-------------------------- END saveTheCheckin ------------------------------------------


    //_____________________________________________________________________________________
    //@@@@@@@@@@ BACK TO FIRST STEP @@@@@@@@
    const backToFirstStep = () => {
        deleteTheCheckin(checkinId.current);
        setIsLastStep(false);
        toggleIsListening(false);
        setTranscriptMessage("How are you feeling?");
        setSummaryText("");

        setSpokenMessage('');
        setTranscribedText('');

        if (settings.fudrik_speaks) tminusSpeechRecognition();
    };
    //-------------------------- END backToFirstStep ------------------------------------------


    const handleOpenModal = () => {
        isThisModalOpen.current = true;
        loggit('Opening TouchpointCheckinModal');
        setIsSpeechRecognitionEnabled(true);    
        if (settings.fudrik_speaks) {
            tminusSpeechRecognition(); // start the speech recognition in 3... 2... 1...beeeeeeeep
        } else {
            if (!settings.speech_recognition) focusTranscribedTextInput();   // focus into the transcribed text input
        }; //  otherwise, we wait for manual activation with buttons
        toggleIsListening(false);
    };

    const handleCloseModal = () => {
        loggit('Closing TouchpointCheckinModal');
        
        setIsLastStep(false);
        toggleIsListening(false);
        setIsFetching(false);
        setIsSpeechRecognitionEnabled(false);        
        
        setTranscriptMessage("How are you feeling?");
        setAssistantMessage('');
        setTranscribedText('');
        setSummaryText('');
        setSpokenMessage('');
        
        isThisModalOpen.current = false; // a useState isn't fast enough

        // stop any ongoing speech and clear the queue
        if (settings.fudrik_speaks) {
            if (typeof window !== "undefined" && window.speechSynthesis) {
                loggit('Stopping speech synthesis...clearing the queue.');
                window.speechSynthesis.cancel();
            }
        }
        onClose();
    };


    if (!isOpen || isSettingsLoading) return null;

    return (
        <Modal show 
        onHide={handleCloseModal}
        onEntered={handleOpenModal} 
        onExit={handleCloseModal}
        size="lg"
        className="top-aligned-modal blurred-modal" 
        centered>
            <Modal.Header closeButton>
                <Modal.Title>Touchpoint Checkin - Record How You're Feeling</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
            <div>
                Now that you're recording what you're eating and when, 
                you can start recording how you're feeling 
                and start looking for possible connections between the two.
            </div>
                    {!isLastStep ? ( // it's the first step, which means we're asking the question...
                    <SpeechRecognition 
                        // props
                        isEnabled={isSpeechRecognitionEnabled}
                        isListening={isListening}
                        processBarActivated={isProcessBarActivated}
                        processBarMessage={processBarMessage}
                        focusTextInput={focusTextInput.current}
                        transcriptMessage={transcriptMessage}
                        assistantMessage={assistantMessage}
                        spokenMessage={spokenMessage}
                        transcribedText={transcribedText}
                        isFetching={isFetching}
                        isLastStep={isLastStep}
                        conversationModeOverride={conversationModeOverride}
                        // callbacks
                        handleDisabled={() => setIsSpeechRecognitionEnabled(false)} //callback function to disable speechrecognition
                        toggleIsListening={toggleIsListening}
                        setSpokenMessage={setSpokenMessage}
                        focusTextInputDone={() => focusTextInput.current = false}
                        setTranscribedText={setTranscribedText}
                        triggerApiCall={handleApiCall} 
                        setConversationModeOverride={setConversationModeOverride}
                        />
                    ) : ( // it's not first step, which means we're segmenting...
                        <div className='p-5'>
                        <Row>
                            {isFetching ? (
                                <h4><AnimatedText newMessage = {summaryText} /></h4>
                            ) : (
                                summaryText.split('\n').map((line, i) => <h3 key={i}>{line}</h3>)
                            )}
                        </Row>
                        <Row className='w-100 p-2 no-padding'>
                            <Button variant="secondary" className={`w-100 mb-2 no-padding ${buttonSizeClass}`} onClick={() => backToFirstStep()}>Go back and try again.</Button>
                        
                            <Button variant="success" className={`w-100 no-padding ${buttonSizeClass}`}  onClick={() => handleCloseModal()}>Looks good. Keep it!</Button>
                        </Row>
                        </div>
                    )}
                </Container>
            </Modal.Body>
        </Modal>
    )

};
export default TouchpointCheckinModal;