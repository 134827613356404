import React, { useState, useRef } from 'react';
import { Container, Button } from 'react-bootstrap';
import { loggit, saveFudrikSettings } from '../utils/helpers';

import { useSettings } from '../settings/SettingsContext';

import LogMealModal from '../modals/LogMealModal';
import FudrikSpeaksToggle from '../settings/FudrikSpeaksToggle';


const FudrikHome = () => {
    const { settings, updateSettings } = useSettings();
    const [isLogMealModalOpen, setIsLogMealModalOpen] = useState(false);

    const buttonSizeClass = settings.accessibility ? "btn-lg" : "btn-sm";

    var loggingMealType = useRef('');

    const handleSettingsToggle = async (key, value) => {
        // Update the siteSettings state
        const updatedSettings = { ...settings, [key]: value };
        updateSettings(updatedSettings);
      
        // Save the updated settings to the API
        saveFudrikSettings(updatedSettings);
    };
    
    const handleOpenLogMealModal = (mealType) => {
        loggit('Opening modal for mealType: ', mealType, ' with fudrik_speaks: ', settings.fudrik_speaks);
        loggingMealType.current = mealType;
        setIsLogMealModalOpen(true);
    };

    const handleCloseLogMealModal = () => {
        loggingMealType.current = '';
        setIsLogMealModalOpen(false);
    };

    return (
        <Container>
            <div className='justify-content-center'>
                <div className="meal-buttons justify-content-center row mb-5">
                    <div className="col-12 col-md-2 mx-auto text-center">
                        <Button variant='info' className={`meal-button outer-glow-dark ${buttonSizeClass}`} onClick={() => handleOpenLogMealModal('Snack')}>
                            Snack
                        </Button>
                    </div>
                    <div className="col-12 col-md-2 mx-auto text-center">
                        <Button variant='info' className={`meal-button outer-glow-dark ${buttonSizeClass}`} onClick={() => handleOpenLogMealModal('Breakfast')}>
                            Breakfast
                        </Button>
                    </div>
                    <div className="col-12 col-md-2 mx-auto text-center">
                        <Button variant='info' className={`meal-button outer-glow-dark ${buttonSizeClass}`} onClick={() => handleOpenLogMealModal('Brunch')}>
                            Brunch
                        </Button>
                    </div>
                    <div className="col-12 col-md-2 mx-auto text-center">
                        <Button variant='info' className={`meal-button outer-glow-dark ${buttonSizeClass}`} onClick={() => handleOpenLogMealModal('Lunch')}>
                            Lunch
                        </Button>
                    </div>
                    <div className="col-12 col-md-2 mx-auto text-center">
                        <Button variant='info' className={`meal-button outer-glow-dark ${buttonSizeClass}`} onClick={() => handleOpenLogMealModal('Dinner')}>
                            Dinner
                        </Button>
                    </div>
                </div>
                
                <div className='mb-5 d-flex justify-content-center fudrik-text-light'>
                    <FudrikSpeaksToggle  settingKey="fudrik_speaks" value={settings.fudrik_speaks} onToggle={handleSettingsToggle} />
                </div>
            </div>
            <LogMealModal isOpen={isLogMealModalOpen} onClose={handleCloseLogMealModal} loggingMealType={loggingMealType.current} />
        </Container>    
    );
}

export default FudrikHome;
