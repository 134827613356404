import React, { useState, useEffect } from 'react';
import '../App.css';

const LongPressButton = ({ onSubmit, initialColor = 'blue', finalColor = 'white', holdTime = 2000, text = 'Submit', ...props }) => {
    const [holding, setHolding] = useState(false);
    const [holdStart, setHoldStart] = useState(null);
    const [style, setStyle] = useState({});

    const startHold = () => {
        setHoldStart(Date.now());
        setHolding(true);
        setStyle({
            backgroundImage: `linear-gradient(to right, ${finalColor} 0%, ${initialColor} 100%)`,
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%'
        });
    }

    const endHold = () => {
        setHolding(false);
        const holdDuration = Date.now() - holdStart;
        if(holdDuration >= holdTime) {
            onSubmit();
        };
        // setStyle({
        //   backgroundImage: `linear-gradient(to right, ${finalColor} 101%, ${initialColor} 0%)`,
        // });
        flashit();
        setHoldStart(null);
    }

    const flashit = () => {
        setStyle({
            backgroundImage: `linear-gradient(to right, ${finalColor} 0%, ${initialColor} 100%)`,
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%'
        });
        setTimeout(() => {
            setStyle({
                backgroundImage: 'none',
            });
        }, 10);
    }

    useEffect(() => {
        let holdInterval;
        if(holding) {
            holdInterval = setInterval(() => {
                const holdDuration = Date.now() - holdStart;
                if(holdDuration >= holdTime) {
                    clearInterval(holdInterval);
                    endHold();
                } else {
                    const percentage = (holdDuration / holdTime) * 100;
                    setStyle({
                        backgroundImage: `linear-gradient(to right, ${finalColor} ${percentage}%, ${initialColor} ${percentage}%)`,
                        backgroundPosition: 'right bottom',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100%'
                    });
                }
            }, 20);
        }
        return () => clearInterval(holdInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [holding]);

    return (
        <button 
            className="long-press-button outer-glow-dark"
            onMouseDown={startHold}
            onMouseUp={endHold}
            onTouchStart={startHold}
            onTouchEnd={endHold}
            style={style}
            {...props}
        >
            {text}
        </button>
    )
}

export default LongPressButton;
