import React, { useState, useEffect } from 'react';
import { Collapse, Modal, Button } from 'react-bootstrap';
import { getCookie } from './utils/cookie';
import './App.css';

import SettingsPrimer from './settings/SettingsPrimer';
import SettingsToggles from './settings/SettingsToggles';
import { useSettings } from './settings/SettingsContext';

import LoginModal from './modals/LoginModal'
import RegisterModal from './modals/RegisterModal'
import LogIssueModal from './modals/LogIssueModal';
import LoggingHistory from './modals/LoggingHistory';
import WhatsappMeals from './modals/WhatsappMeals';
import CognitionTestModal from './modals/CognitionTestModal';

import LogoutButton from './components/LogoutButton';
import BurndownSpindleGraph from './components/BurndownSpindleGraph';
import LineChartContainer from './components/LineChartContainer';
import PercentAreaChartContainer from './components/PercentAreaChartContainer';

import fudrikLogo from './images/fudrik-logo-v2.png';
import whatsappLogo from './images/whatsapp-logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './/bootstrap/bootstrap-dark.min.css';
import TouchpointCheckinModal from './modals/TouchpointCheckinModal';

function App() {
    const { isSettingsLoading } = useSettings();

    const [isIssuesModalOpen, setIssuesModalOpen] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [isWhatsappMealsModalOpen, setIsWhatsappMealsModalOpen] = useState(false);
    const [isCognitionTestModalOpen, setIsCognitionTestModalOpen] = useState(false);

    useEffect(() => {
        const jwtToken = getCookie('jwtToken');
        try {
            if (!jwtToken || jwtToken === 'undefined') {
                console.log('No token present. Opening login.');
                openLoginModal();
            } else {
                setIsLoggedIn(true);
            }
        } catch(error) {
            console.log('Error checking token. Opening login:', error);
            openLoginModal();
        }
    }, []);
    
    const openLoginModal = () => { 
        setLoginModalOpen(true);
        setRegisterModalOpen(false);
    }
    const openRegisterModal = () => {
        setLoginModalOpen(false);
        setRegisterModalOpen(true);
    }
    
    const openIssuesModal = () => {
        setIssuesModalOpen(true);
    };
    const closeIssuesModal = () => {
        setIssuesModalOpen(false);
    };

    const handleOpenHistoryModal = () => {
        console.log('Opening history modal');
        setIsHistoryModalOpen(true);
    };
    const handleCloseHistoryModal = () => {
        setIsHistoryModalOpen(false);
    };

    const handleOpenWhatsappMealsModal = () => {
        console.log('Opening WhatsappPending modal');
        setIsWhatsappMealsModalOpen(true);
    };
    const handleCloseWhatsappMealsModal = () => {
        console.log('Closing WhatsappPending modal');
        setIsWhatsappMealsModalOpen(false);
    };

    const [isTouchpointCheckinModalOpen, setIsTouchpointCheckinModalOpen] = useState(false);
    const handleOpenTouchpointModal = () => {
        console.log('Opening TouchpointCheckin modal');
        setIsTouchpointCheckinModalOpen(true);
    };
    const handleCloseTouchpointModal = () => {
        console.log('Closing TouchpointCheckin modal');
        setIsTouchpointCheckinModalOpen(false);
    };

    const handleOpenCognitionTestModal = () => {
        setIsCognitionTestModalOpen(true);
    };
    const handleCloseCognitionTestModal = () => {
        setIsCognitionTestModalOpen(false);
    };

    
    return (
        <div className="App">
            <Modal  className='blurred-modal' show={isLoginModalOpen} onHide={() => {}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Füdrik | Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginModal isOpen={isLoginModalOpen} setIsOpen={setLoginModalOpen} openRegisterModal={openRegisterModal}/>
                    <button onClick={openRegisterModal}>Don't have an account? Register</button>
                </Modal.Body>
            </Modal>
            <Modal  className='blurred-modal' show={isRegisterModalOpen} onHide={() => {}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Füdrik | Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegisterModal isOpen={isRegisterModalOpen} setIsOpen={setRegisterModalOpen} openLoginModal={openLoginModal}/>
                    <button onClick={openLoginModal}>Already have an account? Login</button>
                </Modal.Body>
            </Modal>
                
        {isLoggedIn && (
                <>
                <div className='home-header'>
                    <img src={fudrikLogo} alt="Fudrik Logo" style={{height: '150px'}} />
                    <h1 className="mt-4 fudrik-logo">Füdrik</h1>
                    <h3 className='fudrik-tagline mb-5'>"Thy meal be done..."</h3>
                </div>
                <p className='fudrik-text-light'>An AI driven Food Logging experience.<br/> Just click a button and describe what you ate.</p>
                <SettingsPrimer />
                <div>
                    <Button variant='info' className='button-rounded button-hot  mb-5 btn-sm outer-glow-dark' onClick={handleOpenHistoryModal}>View/Edit Logging History</Button>
                    <LoggingHistory isOpen = {isHistoryModalOpen} onClose = {handleCloseHistoryModal} />
                </div>
                <hr/>
                <div className='mt-5 mb-5'>
                    {/* eslint-disable-next-line */}
                <a href="#" className="button-rounded btn-sm" style={{display: 'inline-flex', alignItems: 'center', textDecoration: 'none', color: 'inherit'}} onClick={handleOpenWhatsappMealsModal}>
                    <img src={whatsappLogo} alt="WhatsApp Logo" style={{height: '40px', marginRight: '8px'}} />
                    <span className='fudrik-text-light'><b>WhatsApp</b> integration is in Beta!</span>
                </a>
                </div>
            {!isSettingsLoading && 
                <div className='mb-5'>
                    <Button variant='light' className='button-rounded btn-sm outer-glow-dark' onClick={handleOpenTouchpointModal}>NEW Feature!! Log how you're feeling.</Button>
                    <TouchpointCheckinModal isOpen = {isTouchpointCheckinModalOpen} onClose = {handleCloseTouchpointModal} />
                </div>
            }
                <WhatsappMeals isOpen = {isWhatsappMealsModalOpen} onClose = {handleCloseWhatsappMealsModal} />
                <hr/>
                <div>
                    <LogIssueModal isOpen={isIssuesModalOpen} onRequestClose={closeIssuesModal} />
                </div>
                <div  className='mb-4 justify-content-center align-items-center'>
                    <BurndownSpindleGraph />
                </div>
                <div  className='justify-content-center align-items-center'>
                    <LineChartContainer />
                </div>
                <div  className='justify-content-center align-items-center'>
                    <PercentAreaChartContainer />
                </div>
                <div>
                    <Button variant='info' className="button-rounded button-hot mt-5 mb-5 btn-sm outer-glow-dark" onClick={openIssuesModal}>Make a Suggestion or Report an Issue</Button>
                </div>

                <div className="p-3 mb-4 border dark-text bg-light usage-tip-box outer-glow-dark">
                    <h4 onClick={() => setCollapseOpen(!collapseOpen)} style={{cursor: "pointer"}}>
                        What the Füdrik? {collapseOpen ? "↑" : "↓"}
                    </h4>
                    <Collapse in={collapseOpen}>
                        <div className="usage-tips">
                            <div className="elevator-pitch p-3 w-100">
                                <p>
                                    It's tough enough trying to remember what you ate yesterday, let alone last week. Last month? 
                                    Forget about it. You want to track what you're eating, but it's nigh impossible to find the time to fill out some long form.
                                    Füdrik was created to solve this problem. Just describe what you ate in a conversational manner and forget it. 
                                    Simple as that. As your food logging assistant, Füdrik remembers for you. Thy meal be done...
                                </p>
                                <p>
                                    Our focus is on letting you be you. Because we understand that everybody thinks about food differently.
                                    Some people think in terms of weights, measurements and portion size while some just like food.
                                    Füdrik gives you the flexibility to describe your meals in a way that makes sense to you. 
                                    We base our insights on the data as it relates to you. Looking for trends and patterns in your eating habits. 
                                    So whether your descriptions are general or specific, it's fine.
                                </p>
                                <p> 
                                    Our goal is to help you visualize your eating habits in and easy intuitive way, to provide valuable insights that can help you 
                                    better understand your eating habits so you can strive to feel your best. It all starts with collecting the data. So start recording today. 
                                    Click on a meal type above to get started with your first meal. It takes less than a minute. 
                                    Read the Tips below to get the most out of Füdrik. Fine tune the settings to get an experience that works for you. 
                                </p>
                            </div>
                            <div className='justify-content-center text-center'>
                                <h4>Pro Tips</h4>
                            </div>
                            <ul>
                                <li>Make sure you are in a relatively quiet place where nobody close to you is talking.</li>
                                <li>If it's too loud where you are, you can disable speech recognition by unchecking the Speech Recognition checkbox below.</li>
                                <li>If speech recognition is on, when the modal pops open, it will start listening for your description. So be prepared to start speaking.</li>
                                <li>You can click into the field to edit like normal. If you need to start over, just click Reset or Cancel.</li>
                                <li>If Füdrik gets something wrong, just correct it like you're dictating to your assistant.</li>
                                <li>When you are done speaking, you can click the button to submit or just wait a few seconds and it will automatically submit.</li>
                                <li>You can turn off auto submit by unchecking the Auto Submit checkbox below. (Recommended for people with difficulty speaking.)</li>
                                <li>Toggle the Accessibility setting below to make the buttons bigger, for those that have a harder time on small screens.</li>
                                <li>If you have any comments, issues or suggestions, please click on the link below to add it to our development planning board.</li>
                                <li>Click one of the meal types above to begin.</li>
                            </ul>    
                            <div className='w-100'>
                                {!isSettingsLoading &&(
                                    <SettingsToggles />
                                )}
                            </div>
                            <div className='d-flex mt-5 mb-3 justify-content-center'>
                                <LogoutButton />
                            </div>
                        </div>
                    </Collapse>
                </div>

                <div>
                    <Button variant='info' className="button-rounded mt-5 mb-5 btn-sm outer-glow-dark" onClick={handleOpenCognitionTestModal}>Cognition Test BETA</Button>
                </div>
                <CognitionTestModal isOpen={isCognitionTestModalOpen} onClose={handleCloseCognitionTestModal} />
               
        </>
                    )}
            </div>
    );
}

export default App;



