
import { getCookie } from './cookie';
const jwtToken = getCookie('jwtToken');


//_______________________________________________________
//@@@@@@@@@@@ LOGGIT @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export function loggit(...params) {
    console.log(...params);
        // if (process.env.NODE_ENV !== 'production') {
        //   console.log(...params);
        // }
}
//------------- END loggit --------------------------------



//_______________________________________________________
//@@@@@@@@@@ HANDLE API REQUEST ERRORS @@@@@@@@@@@@@@@@@@
export async function handleApiError(response) {
    if (!response.ok) {
        let errorData;
        try {
            errorData = await response.json(); // try to get more error details
        } catch {
            errorData = { error: 'Server error' }; // in case the response is not a json
        }
        throw new Error(errorData.error);
    }
    return response;
}
//------------- END handleApiError -----------------------



//_______________________________________________________
//@@@@@@@@@@ MEAL BREAKDOWN MESSAGES @@@@@@@@@@@@@@@@@@@@
export function getBreakdownMessage() {
    const mealBreakdownIntro = "Thy meal be done!!!... ";
    const mealBreakdownMessages = [
        "I'm generating nutritional insights for you now. Please don't close this window, but feel free to go do other things.",        
        "I'm currently generating nutritional insights for you. Please refrain from closing this window, but feel free to engage in other activities.",
        "I'm generating valuable nutritional insights for you at this moment. Kindly keep this window open while you pursue other tasks.",
        "I'm in the process of generating nutritional insights for you. While you're welcome to explore other endeavors, please keep this window open.",
        "I'm presently working on generating nutritional insights exclusively for you. Remember to keep this window open, but feel free to venture into other pursuits.",
        "I'm generating insightful nutrition information for you as we speak. Keep this window open, but feel free to look into other matters.",
        "I'm in the midst of generating nutritional insights for you. While you continue with other activities, please keep this window open.",
        "I'm actively generating nutritional insights tailored to your needs. Please keep this window open as you explore other tasks.",
        "I'm generating comprehensive nutritional insights for you now. While you attend to other matters, kindly keep this window open.",
        "I'm currently working to generate nutritional insights for you. Feel free to engage in other tasks, but keep this window open."
    ];
    const getRandomMealBreakdownMessage = () => {
        const randomIndex = Math.floor(Math.random() * mealBreakdownMessages.length);
        return mealBreakdownIntro + " " + mealBreakdownMessages[randomIndex];
    }
    const response = getRandomMealBreakdownMessage();
    return response;
}
//------------- END getBreakdownMessage ------------------



//_______________________________________________________
//@@@@@@@@@@ SAVE FUDRIK SETTINGS @@@@@@@@@@@@@@@@@@@@@@@
export async function saveFudrikSettings(updatedSettings) {
    loggit('Saving settings to API: ', updatedSettings);
    try {
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_AUTH_URI}account/settings/update/`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSettings),
        });
        const data = await response.json();
        loggit('Site settings saved: ', data);

    } catch(error) {
        console.log('Error saving site settings:', error);
    }
}
//------------- END saveFudrikSettings -------------------




//_______________________________________________________
//@@@@@@@@@@ SPEECH TO TEXT W/GOOGLE @@@@@@@@@@@@@@@@@@@@@
export async function transcribeAudio(audioBlob) {
    try {
        const formData = new FormData();
        formData.append('audio', audioBlob);
    
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_API_URI}transcribe`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            body: formData,
        });
        await handleApiError(response);
        const data = await response.json();
        
        return data.transcript;
    } catch (error) {
        console.error('     • Error transcribing the audio:', error);
    }
};
//------------- END transcribeAudio -----------------------




//_______________________________________________________
//@@@@@@@@@@ SAVE COGNITION SUBTEST W/AUDIO RECORDING @@@@@@@@@@@@@@
export async function saveCognitionSubtest(audioBlob, testSessionData, subtestData) {
    loggit('Saving cognition subtest to API: ', subtestData);
    try {
        const formData = new FormData();
        formData.append('audio', audioBlob);
        formData.append('test_session_data', JSON.stringify( testSessionData ));
        formData.append('subtest_data', JSON.stringify( subtestData ));
    
        const response = await fetch(`${process.env.REACT_APP_COGNITION_TEST_URI}vocal/subtest/new`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            body: formData,
        });
        await handleApiError(response);
        const data = await response.json();
        
        return true;
    } catch (error) {
        console.error('     • Error saving the subtest:', error);
        return false;
    }
};
//------------- END saveCognitionSubtest -----------------------