import React, { useEffect, useState } from 'react';
import { loggit } from '../utils/helpers';
import { getAbridgedHistory } from '../utils/reporting';

import PercentAreaChart from './PercentAreaChart';

const PercentAreaChartContainer = () => {
    const days = 28;

    const [loading, setLoading] = useState(true);
    const [nutritionData, setNutritionData] = useState(null);

    
    useEffect(() => {
        getAbridgedHistory(days)
            .then(data => {
                setNutritionData(data);
                setLoading(false);
            })
            .catch((error) => {
                loggit('     • Error getting nutrition data: ', error);
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <>
        {loading ? (
            // Show a loading spinner or some other placeholder
            <div className='d-flex justify-content-center'>Loading...</div>
        ) : (
            <div className='d-flex flex-column align-items-center'>
                <div className='mt-5 mb-3 fudrik-tagline'>
                    <h2>Macros Percentages</h2>
                </div>
                <div>

                </div>
                <div className='outer-glow-dark w-100'>
                    <div className='inner-glow-light burndown-profile'>
                        <div className="d-flex flex-wrap justify-content-center line-chart" style={{ width: '100%', height: '420px'}}>
                            {nutritionData && (
                                <PercentAreaChart data = {nutritionData}/>
                            )}
                        </div>
                        <hr />
                        <div className='mt-4 mb-5 fudrik-text-light'>
                            <div className='d-flex justify-content-center'>
                                <div className='spindle-xplnd text-justify'>
                                    <p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    );

};

export default PercentAreaChartContainer;





    