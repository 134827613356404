import React, { createRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loggit, handleApiError } from '../utils/helpers';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = createRef();


const RegisterModal = ({ isOpen, setIsOpen }) => {
  
  const handleClose = () => {
    setIsOpen(false);
  };
  
  const initialValues = {
    username: '',
    password: '',
    confirmPassword: '',
    email: ''
  };
  
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    email: Yup.string().email('Email is invalid').required('Email is required')
  });
  
  const onSubmit = async (values, { setSubmitting }) => {
    try {
        let recaptchaValue = '';
            // Only execute reCAPTCHA if not in development environment
            if (process.env.NODE_ENV !== 'development') {
                recaptchaValue = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();
            }

        loggit(`Attempting to register at API ${process.env.REACT_APP_FUDRIK_AUTH_URI}register/ with recaptcha value: ${recaptchaValue}`)
        const response = await fetch(`${process.env.REACT_APP_FUDRIK_AUTH_URI}register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                username: values.username,
                password: values.password,
                email: values.email,
                recaptcha: recaptchaValue
             }),
        });
        await handleApiError(response);
        const data = await response.json();
        loggit('Received response data: ', data);
        alert(data.username[0])
        // success. reload page
        window.location.reload();
    } catch (error) {
        console.error('Error logging in:', error);
        alert(error.message);
        window.location.reload();
    }
    setSubmitting(false);
    handleClose();
  };
  
  return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
          <div className="form-group">
            <Field name="username" type="text" placeholder="Username" className="form-control"/>
            <ErrorMessage name="username" component="div" className="text-danger" />
          </div>
        
          <div className="form-group">
            <Field name="email" type="text" placeholder="Email" className="form-control"/>
            <ErrorMessage name="email" component="div" className="text-danger" />
          </div>
        
          <div className="form-group">
            <Field name="password" type="password" placeholder="Password" className="form-control"/>
            <ErrorMessage name="password" component="div" className="text-danger" />
          </div>
        
          <div className="form-group">
            <Field name="confirmPassword" type="password" placeholder="Confirm Password" className="form-control"/>
            <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
          </div>
        
          <div className="form-group text-right">
            <button type="submit" disabled={isSubmitting} className="btn btn-primary auth-button">Register</button>
          </div>
          {process.env.NODE_ENV !== 'development' && 
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={recaptchaRef} size="invisible" />
            }
        </Form>
        
        )}
      </Formik>
  );
};

export default RegisterModal;


