import React, { useState, useRef, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';

import { loggit } from '../utils/helpers';
import { openaiGetHaiku, openaiGetSegmentation, openaiLogMeal } from '../utils/openai';

import { useSettings } from '../settings/SettingsContext';

import MealSegmentsList from './MealSegmentsList';
import AnimatedText from './AnimatedText';
import SpeechRecognition from '../components/SpeechRecognition';



const LogTheMeal = ({ meal, onHide }) => {
    const { settings } = useSettings();

    const [modalMessage, setModalMessage] = useState("");
    const [summaryText, setSummaryText] = useState("Retrieving Breakdown");
    
    const [haikuLines, setHaikuLines] = useState(["Hmmmmmm... 🤔"]);
    const mealType = useRef("");
    
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    useEffect (() => {
        if (isFirstStep) {
            focusTranscribedTextInput();
        }
    }, [isFirstStep]);
    useEffect(() => {
        if (isFetching) {
            setModalMessage("Hang tight...");
        } else {
            setModalMessage("");
        }
    }, [isFetching]);

    const buttonSizeClass = settings.accessibility ? "btn-lg" : "btn-sm";
    const finalMessage = "We're generating nutritional insights for you now. Please keep this window open until it is complete";
    

    //_____________________________________________________________________________________
    //@@@@@@@@@@ SPEECH RECOGNITION RELATED CODE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const [conversationModeOverride, setConversationModeOverride] = useState(true); // used to override the conversation mode in the SpeechRecognition component
    //eslint-disable-next-line
    const [isFinalStep, setIsFinalStep] = useState(false);
    const [transcriptMessage, setTranscriptMessage] = useState("");
    //eslint-disable-next-line
    const [assistantMessage, setAssistantMessage] = useState("Please describe what you ate...");
    const [spokenMessage, setSpokenMessage] = useState("");

    const [isProcessBarActivated, setIsProcessBarActivated] = useState(false);
    const [processBarMessage, setProcessBarMessage] = useState("");
    const [isListening, setIsListening] = useState(false);
    // callback for the SpeechRecognition component to toggle the isListening state
    const toggleIsListening = (bool) => {
        if (bool) {
            setIsListening(true);
            setIsProcessBarActivated(true);
            setProcessBarMessage("Go ahead. I'm Listening...");
        } else {
            setIsListening(false);
            setIsProcessBarActivated(false);
            setProcessBarMessage('');
        }
    };

    const [transcribedText, setTranscribedText] = useState(''); // used to hold the new transcribed text with which we summarize meal
    const newTranscribedText = useRef(''); // used to hold the new transcribed text with which we summarize meal
    useEffect(() => {
        loggit('     • Setting new transcribed text: ', transcribedText)
        newTranscribedText.current = transcribedText;
    }, [transcribedText]);
        
    //@@@@@@@@@@ FOCUS INTO THE TRANSCRIBED TEXT INPUT @@@@@@@@@
    // const transcribedTextInputRef = useRef(null);   
    var focusTextInput = useRef(false);
    const focusTranscribedTextInput = () => {
        focusTextInput.current = true; // this will trigger the focusTextInput effect within the speechrecognition component
    };

    //@@@@@@@@@@ SPEECH RECOGNITION COMPONENT Activation Controllers @@@@@@@@@@@@@@@@@@@@@@
    const [isSpeechRecognitionEnabled, setIsSpeechRecognitionEnabled] = useState(settings.speech_recognition);
    useEffect(() => {
        if (settings.speech_recognition || settings.fudrik_speaks) {
            setIsSpeechRecognitionEnabled(true);
        } else {
            setIsSpeechRecognitionEnabled(false);
        }
    }, [settings.speech_recognition, settings.fudrik_speaks]);


    //@@@@@@@@@@ T-Minus SPEECH RECOGNITION @@@@@@@@@@
    //eslint-disable-next-line
    const tminusSpeechRecognition = () => {  
        // create 3 second countdown timer
        var tminusCountdown = 3;
        const countItDown = () => {
            if (tminusCountdown > 0) {
                // setSpokenMessage(tminusCountdown);
                setTranscriptMessage(`Active Listening begins in... ${tminusCountdown}`);
                tminusCountdown -= 1;
                setTimeout(() => {
                    countItDown();
                }, 1000);
            } else {
                tminusCountdown = 3;
                setTranscriptMessage("What did you eat?");
                setIsListening(true);
            }
        };
        countItDown();
    };
    //-------------------------- END SPEECH RECOGNITION ------------------------------------------

    let mealSegments = useRef([]);
    const updateMealSegments = (newMealSegments) => {
        mealSegments.current = newMealSegments;
    }

    const backToFirstStep = () => {
        console.log('mealSegments.current: ', mealSegments.current);
        setIsFirstStep(true);
        setSummaryText("Retrieving Breakdown");
        mealSegments.current = [];
    };

//_____________________________________________________________________________________
    //@@@@@@@@@@ TOGGLE THE MEAL EXTRACTION @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const toggleApiCalling = (bool) => {
        if (bool) {
            loggit('     • Toggling meal extraction BEGINS - setting isListening to "false".');
            toggleIsListening(false);
            setIsFetching(true);
            setIsFirstStep(false);
        } else {
            loggit('     • Toggling meal extraction ENDS.');
            setIsFetching(false);
            setTranscribedText(''); // reset the transcribed text in this and child components
            // if (settings.fudrik_speaks && !isLastStep && !isMobile) toggleIsListening(true); // if fudrik_speaks is enabled, and we're not on the last step, and we're not on mobile, then automatically toggle listening back on
        }
    };
    //---------------------- END toggleApiCalling ---------------------------------------

    // get segmentation
    const handleApiCall = async (transcriptRequest) => {
        toggleApiCalling(true);
        
        // Get the segmentation from the API endpoint get/segmentation
        try {
            const segmentedList = await openaiGetSegmentation(transcriptRequest);
            if (Object.keys(segmentedList).length > 0) { // if the api returns a meal segmentation, add it to the mealSegments object
                mealSegments.current = JSON.parse(segmentedList.meal_segments);
                mealType.current = segmentedList.meal_type;
                setSummaryText(mealType.current + " Breakdown");
            }  // else if the api returns nothing, do nothing
            
        } catch(error) {
            loggit('     • Error getting segmentation segmentedList:', error);
            alert('Apologies... Something went wrong. Please try again later.');
        }
        toggleApiCalling(false);
    };


    // log it
    const logTheMeal = async () => {
        setIsFetching(true);

        setIsLastStep(true); // triggers the loading text and haiku

        // get the haiku
        const meal_summary = Object.entries(mealSegments.current).reduce((accumulated, [key, value]) => {
            return accumulated + `${key} `;
        }, '');
        const generatedHaiku = await openaiGetHaiku(meal_summary);
        setHaikuLines(generatedHaiku.split('\n'));
        // DONE with Haiku

        // log the meal
        const mealSummary = Object.entries(mealSegments.current).reduce((accumulated, [key, value]) => {
            return accumulated + `${key}, calories - ${value}. `; 
        }, '');
        const body = {
            'meal_type': mealType.current,
            'date_created': meal.date_created,
            'meal_summary': mealSummary,
            'meal_prep_id': meal.meal_prep_id,
        };
        console.log('     • Sending logTheMeal request to API: ', body)

        const loggedMeal = await openaiLogMeal(body);
        
        setIsFetching(false);
        if (loggedMeal) {
            onHide();
        } else {
            alert('Apologies... Something went wrong. Please try again later.');
            backToFirstStep();
        }
    };

    return (
        <>
            <Row className="justify-content-center">
                {isFirstStep ? ( // waiting for user to enter in meal description
                    <SpeechRecognition 
                        // props
                        isEnabled={isSpeechRecognitionEnabled}
                        isListening={isListening}
                        processBarActivated={isProcessBarActivated}
                        processBarMessage={processBarMessage}
                        focusTextInput={focusTextInput.current}
                        transcriptMessage={transcriptMessage}
                        assistantMessage={assistantMessage}
                        spokenMessage={spokenMessage}
                        transcribedText={transcribedText}
                        isFetching={isFetching}
                        isLastStep={isLastStep}
                        conversationModeOverride={conversationModeOverride}
                        // callbacks
                        handleDisabled={() => setIsSpeechRecognitionEnabled(false)} //callback function to disable speechrecognition
                        toggleIsListening={toggleIsListening}
                        setSpokenMessage={setSpokenMessage}
                        focusTextInputDone={() => focusTextInput.current = false}
                        setTranscribedText={setTranscribedText}
                        triggerApiCall={handleApiCall} 
                        toggleApiCalling={toggleApiCalling}
                        setConversationModeOverride={setConversationModeOverride}
                        />
                ) : ( // it's not first step, which means we're segmenting...
                    (!isLastStep) ? ( // it's not the last step, which means we're showing the summary and waiting for a response 
                        <>
                            <div className='d-flex mb-3 justify-content-center'>
                                <div className={`loading-bar ${isFetching ? '' : 'hidden'}`}></div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <h6 className='modal-message text-danger px-4'>{modalMessage}</h6>
                            </div>
                            <Row>
                                
                                {isFetching ? (
                                    <h4><AnimatedText newMessage = {summaryText} /></h4>
                                    ) : (
                                    <h3>{summaryText}</h3>
                                )}
                                
                                <MealSegmentsList meal_segments={mealSegments.current} onUpdate={updateMealSegments} />
                            </Row>
                            <Row className='w-100 p-2 no-padding'>
                                <Button variant="secondary" className={`w-100 mb-2 no-padding ${buttonSizeClass}`} onClick={() => backToFirstStep()}>Go Back</Button>
                            
                                <Button variant="success" className={`w-100 no-padding ${buttonSizeClass}`}  onClick={() => logTheMeal()}>Log it!</Button>
                            </Row>
                        </>
                    ) : ( // it's the last step, which means we're waiting and showing the haiku
                        <>
                            <Row className='mt-2 text-center'>
                                <div className='mb-4'>
                                    <p>a haiku while you wait...</p>
                                </div>
                                <div className="mb-4 haiku-text fudrik-text-light">
                                    {haikuLines.map((line, index) => (
                                    <div className="haiku-text-size" key={index}>{line}</div>
                                    ))}
                                </div>
                                <br/>
                                <div className='mb-5'>
                                    <i>~Füdrik the Wise</i>
                                </div>
                                <hr/>
                                <p><AnimatedText newMessage = {finalMessage} /></p>
                            </Row>
                        </>
                    )
                )}
            </Row>
        </>
    );
};


export default LogTheMeal;
