import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, FormGroup, FormControl } from 'react-bootstrap';


function LogIssueModal({ isOpen, onRequestClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = async (event) => {
    event.preventDefault();
    const id_list = process.env.REACT_APP_TRELLO_LIST_ID;
    const request_uri = `https://api.trello.com/1/cards?key=${process.env.REACT_APP_TRELLO_API_KEY}&token=${process.env.REACT_APP_TRELLO_TOKEN}`;
    console.log('id_list: ', id_list);
    console.log('request_uri: ', request_uri);

    const data = {
      name: `Füdrik Online - ${getTruncatedMessage()}...`,
      desc: `${name} - ${email}: ${message}`,
      idList: id_list,  // replace with your list ID
      pos: 'top',
    };
    console.log('api request data: ', data);
    try {
      await axios.post(request_uri, data); 
      alert('New issue submitted. Thanks for your feedback!');
      setMessage('');
      onRequestClose();
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  function getTruncatedMessage() {
    let str = message;
    if (str.length > 80) {
      // truncate string to less than 80 characters without breaking up words
      str = str.substring(0, 80);
      str = str.substring(0, Math.min(str.length, str.lastIndexOf(" ")));
    }
    return str;
  }
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} className='blurred-modal' centered>
    <Modal.Header closeButton>
        <Modal.Title>Make a Suggestion / Report a Bug</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form onSubmit={submitForm}>
        <FormGroup controlId="formName">
            <Form.Label>Name:</Form.Label>
            <FormControl type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </FormGroup>
        <FormGroup controlId="formEmail">
            <Form.Label>Email:</Form.Label>
            <FormControl type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </FormGroup>
        <FormGroup controlId="formMessage">
            <Form.Label>Message:</Form.Label>
            <FormControl as="textarea" value={message} onChange={(e) => setMessage(e.target.value)} required />
        </FormGroup>
        <hr/>
        <Button type="submit">Submit</Button>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button onClick={onRequestClose}>Close</Button>
    </Modal.Footer>
    </Modal>

  );
}

export default LogIssueModal;




