import React from 'react';
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, Text } from 'recharts';

const toPercent = (decimal, fixed = 1) => `${(Math.round(decimal * 1000) / 10).toFixed(fixed)}%`;
const toPercentNoDecimals = (decimal) => `${(Math.round(decimal * 1000) / 10).toFixed(0)}%`;


const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;
  return toPercent(ratio, 1);
};

const renderTooltipContent = (o) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => result + entry.value, 0);

  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
          </li>
        ))}
      </ul>
    </div>
  );
};

const PercentAreaChart = ({ data }) => {
    const labelsColor = '#ffffff';   
    


    return (
        <ResponsiveContainer width="90%" height="100%">
        <AreaChart
            width={1500}
            height={400}
            data={data}
            stackOffset="expand"
            margin={{
            top: 10,
            right: 30,
            left: 30,
            bottom: 0,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={{fill: labelsColor, fontSize: '0.75em'}} />
            <YAxis tickFormatter={toPercentNoDecimals} tick={{fill: labelsColor, fontSize: '0.75em'}} />
            <Tooltip content={renderTooltipContent} tick={{fill: labelsColor, fontSize: '0.75em'}}/>
            <Area type="monotone" dataKey="fats" stackId="1" stroke="#FF00C3" fill="#FF00C3" />
            <Area type="monotone" dataKey="carbohydrates" stackId="1" stroke="#F6A4FF" fill="#F6A4FF" />
            <Area type="monotone" dataKey="protein" stackId="1" stroke="#61DAFB" fill="#61DAFB" />
        </AreaChart>
        </ResponsiveContainer>
    );
};

export default PercentAreaChart;
